import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import { Creator } from "@src/core/models/creator";
import { Offer } from "@src/core/models/offer";
import { Order } from "@src/core/models/order";
import { formatEth } from "@src/core/support/format-currency";
import HorizontalLine from "@src/components/shared/horizontal-line";
import OfferContentPreview from "@src/components/offers/content-preview";
import OfferOverview from "@src/components/offers/details/overview";
import * as Styles from "./styles";

interface Props {
  creator: Creator;
  offer: Offer;
  order: Order;
}

const OrdersDetails = ({ creator, offer, order }: Props): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Container>
        <Row>
          <Col sm={12} lg={7}>
            <OfferContentPreview offer={offer} />
          </Col>
          <Col sm={12} lg={5}>
            <Styles.ContentWrapper>
              <Styles.Title>{offer.name}</Styles.Title>
              <Styles.Timestamp>{`Ordered on: ${formatTimestamp(
                order.createdAt
              )}`}</Styles.Timestamp>
              <p>{offer.description}</p>
              {renderTags(offer, creator)}
              <HorizontalLine />
              <OfferOverview offer={offer} />
            </Styles.ContentWrapper>
          </Col>
        </Row>
      </Container>
    </Styles.Wrapper>
  );
};

const renderTags = (offer: Offer, creator: Creator): JSX.Element => {
  return (
    <Styles.TagsWrapper>
      <Styles.CreatorTag>
        <Styles.CreatorImage roundedCircle src={creator.profileImageUrl} />
        <div>
          <Styles.TagLabel>Author</Styles.TagLabel>
          <Styles.TagText>{creator.name}</Styles.TagText>
        </div>
      </Styles.CreatorTag>
      <Styles.PriceTag>
        <Styles.TagLabel>Order value</Styles.TagLabel>
        <Styles.TagText>{formatEth(offer.price)}</Styles.TagText>
      </Styles.PriceTag>
    </Styles.TagsWrapper>
  );
};

const formatTimestamp = (date: Date): string => {
  return moment(date).format("MMMM DD, YYYY - hh:mma");
};

export default OrdersDetails;
