import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Offer } from "@src/core/models/offer";
import { formatEth } from "@src/core/support/format-currency";
import OfferActionButton from "@src/components/offers/action-button";
import OfferThumbnail from "@src/components/offers/thumbnail";
import * as Styles from "./styles";

interface Props {
  offer: Offer;
}

const OfferCard = ({ offer }: Props): JSX.Element => {
  const history = useHistory();

  return (
    <Styles.Wrapper onClick={() => history.push(`/offers/${offer.id}`)}>
      <Card>
        <OfferThumbnail offer={offer} previewSize="large" controls={false} />
        <Card.Body>
          <Styles.TitleWrapper>
            <Card.Title>{offer.name}</Card.Title>
          </Styles.TitleWrapper>
          {renderEthereumPrice(offer)}
          <OfferActionButton offer={offer} />
        </Card.Body>
      </Card>
    </Styles.Wrapper>
  );
};

const renderEthereumPrice = (offer: Offer): JSX.Element => (
  <Styles.EthPrice>{formatEth(offer.price)}</Styles.EthPrice>
);

export default OfferCard;
