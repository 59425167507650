import { useContext } from "react";
import { DropContext } from "@src/core/contexts/drop";
import { Offer } from "@src/core/models/offer";
import { BloctoWalletContext } from "@src/core/contexts/blocto-wallet";
import { MetaMaskWalletContext } from "@src/core/contexts/meta-mask-wallet";
import WalletOpenModalButton from "@src/components/wallet/open-modal-button";
import OfferArrivingSoon from "./arriving-soon";
import OfferBuyNow from "./buy-now";
import OfferExpiredDrop from "./expired-drop";
import OfferSoldOut from "./sold-out";
import * as Styles from "./styles";

interface Props {
  offer: Offer;
}

const OfferCallToAction = ({ offer }: Props): JSX.Element => {
  const isSoldOut = Number(offer.availableCount) === 0;
  const { dropIsActive, dropIsExpired } = useContext(DropContext);
  const { bloctoWallet } = useContext(BloctoWalletContext);
  const { metaMaskWallet } = useContext(MetaMaskWalletContext);
  const currentChainId = bloctoWallet?.chainId || metaMaskWallet?.chainId;
  const offerBuyNow = dropIsActive && !dropIsExpired && !isSoldOut;
  const appChainId = `0x${process.env.REACT_APP_CHAIN_ID}`;
  const matchingChain = currentChainId?.toString() === appChainId;
  const estimatedTotalCost =
    +offer.price + offer.estimatedGas * (metaMaskWallet?.gasPrice || 0);
  const hasEnoughBalance = estimatedTotalCost <= (metaMaskWallet?.balance || 0);

  return (
    <Styles.Wrapper>
      {!hasEnoughBalance && metaMaskWallet && (
        <Styles.NetworkAlert variant="error">
          Not enough funds
        </Styles.NetworkAlert>
      )}

      {offerBuyNow && !matchingChain && metaMaskWallet && (
        <Styles.NetworkAlert variant="error">
          Switch Network to Mint
        </Styles.NetworkAlert>
      )}

      {!metaMaskWallet && <WalletOpenModalButton />}

      {offerBuyNow && matchingChain && hasEnoughBalance && (
        <OfferBuyNow offer={offer} />
      )}

      {!dropIsActive && !dropIsExpired && !isSoldOut && <OfferArrivingSoon />}

      {isSoldOut && <OfferSoldOut />}

      {dropIsExpired && !isSoldOut && <OfferExpiredDrop />}
    </Styles.Wrapper>
  );
};

export default OfferCallToAction;
