import styled from "styled-components";
import fontWeight from "@src/design-tokens/font-weight";
import margin from "@src/design-tokens/margin";

export const Balance = styled.div`
  font-weight: ${fontWeight.medium};
`;

export const Id = styled.div`
  margin-right: ${margin.m3};
`;

export const Logo = styled.img`
  height: 2rem;
  margin-right: ${margin.m2};
`;

export const WalletDetailsMetaMask = styled.div`
  align-items: center;
  color: white;
  display: flex;
`;
