import { Modal } from "react-bootstrap";
import styled from "styled-components";
import border from "@src/design-tokens/border";
import color from "@src/design-tokens/color";
import display from "@src/design-tokens/display";
import fontWeight from "@src/design-tokens/font-weight";
import padding from "@src/design-tokens/padding";

export const ModalContent = styled(Modal)`
  padding-top: 10rem;

  & .modal-dialog {
    display: flex;
    justify-content: center;
    max-width: none;
  }

  & .modal-content {
    align-items: center;
    background-color: transparent;
    border: 0;
    justify-content: center;
    width: auto;
  }

  @media (max-width: ${display.md}) {
    padding-top: ${padding.p0};
  }
`;

export const Title = styled.div`
  color: ${color.neutral.medium};
  font-weight: ${fontWeight.semibold};
  font-size: 2.2rem;
  margin-bottom: 1rem;
`;

export const TitleUnderline = styled.div`
  background-color: ${color.neutral.medium};
  border-radius: ${border.radius.none};
  height: 0.125rem;
  width: 8rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-bottom: 5rem;
`;

export const WalletsList = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${display.md}) {
    flex-flow: column;
  }
`;

export const WalletsListWrapper = styled.div``;

export const Wrapper = styled.div``;
