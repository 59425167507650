import TitleSubSection from "@src/components/shared/title/sub-section";
import { Card } from "react-bootstrap";
import { MessageCard } from "../styles";

const OfferSoldOut = (): JSX.Element => {
  return (
    <MessageCard>
      <Card.Body>
        <TitleSubSection text="Sold out" />
      </Card.Body>
    </MessageCard>
  );
};

export default OfferSoldOut;
