import { Card } from "react-bootstrap";
import TitleSubSection from "@src/components/shared/title/sub-section";
import { MessageCard } from "../styles";

const OfferArrivingSoon = (): JSX.Element => {
  return (
    <MessageCard>
      <Card.Body>
        <TitleSubSection text="Arriving soon" />
      </Card.Body>
    </MessageCard>
  );
};

export default OfferArrivingSoon;
