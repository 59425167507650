import styled from "styled-components";
import light from "@/src/assets/images/AtTheFunctionBg.jpg";

export const $layoutPaddingBottom = "16rem";

export const $layoutPaddingTop = "4.625rem";

export const $contentMinHeight = `calc(100vh - ${$layoutPaddingBottom} - ${$layoutPaddingTop})`;

export const Wrapper = styled.div`
  min-height: 100vh;
  padding: 0;
  position: relative;
  &:before {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: var(--main-bg-color);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: -1;
    pointer-events: none;
  }
`;

export const BackgroundImage = styled.div`
  background-image: url(${light});
  background-repeat: no-repeat;
  position: fixed;
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.5;
  pointer-events: none;
`;
