import { Modal as BootstrapModal } from "react-bootstrap";
import styled from "styled-components";

export const OfferContentModal = styled.div``;

export const Modal = styled(BootstrapModal)`
  & .modal-dialog {
    justify-content: center;
    max-width: none;
  }

  & .modal-content {
    border: 0;
    max-width: 90vw;
  }
`;

export const Image = styled.img`
  width: 100%;
`;
