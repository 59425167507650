const padding = {
  p0: "0",
  p1: "0.25rem" /* 4px */,
  p2: "0.5rem" /* 8px */,
  p3: "1rem" /* 16px */,
  p4: "1.5rem" /* 24px */,
  p5: "2rem" /* 32px */,
  p6: "2.5rem" /* 40px */,
};

export default padding;
