import { Dispatch, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import endpoints from "@src/core/api/endpoints";
import { HttpClient } from "@src/core/api/http-client";
import { CreatorContext } from "@src/core/contexts/creator";
import { mockOffer } from "@src/core/mocks/models/offer";
import { Order } from "@src/core/models/order";
import OrdersDetails from "@src/components/orders/details";
import PageLayout from "@src/components/shared/page-layout";
import * as Styles from "./styles";

interface OrderShowPageParams {
  id: string;
}

const OrderShowPage = (): JSX.Element => {
  const { id } = useParams<OrderShowPageParams>();
  const [order, setOrder] = useState<Order>();
  const { creator } = useContext(CreatorContext);

  useEffect(() => findOrder(id, setOrder), [id]);

  return (
    <PageLayout>
      <Styles.Wrapper>
        {order && creator && (
          <OrdersDetails creator={creator} offer={mockOffer()} order={order} />
        )}
      </Styles.Wrapper>
    </PageLayout>
  );
};

const findOrder = (id: string, setOrder: Dispatch<Order>): void => {
  HttpClient.get<Order>(endpoints.ordersFind(id), (response) => {
    setOrder(response);
  });
};

export default OrderShowPage;
