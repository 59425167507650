export interface Wallet {
  balance: number;
  id: string;
  type: WalletType;
  chainId?: ChainType;
  gasPrice: number;
}

export enum ChainType {
  Mainnet = "0x1",
  Polygon = "0x89",
  Rinkeby = "0x4",
  Goerli = "0x5",
}

export enum WalletType {
  Blocto = "blocto",
  MetaMask = "metaMask",
}
