/* eslint-disable jsx-a11y/media-has-caption */
import { Offer } from "@src/core/models/offer";
import * as Styles from "./styles";

interface Props {
  isVisible: boolean;
  offer: Offer;
  onClose: () => void;
}

const OfferContentModal = ({
  isVisible,
  offer,
  onClose,
}: Props): JSX.Element => {
  return (
    <Styles.OfferContentModal>
      <Styles.Modal
        centered
        show={isVisible}
        onClick={onClose}
        onHide={onClose}
      >
        <video controls autoPlay>
          <source src={offer.mediaUrl} type="video/mp4" />
        </video>
      </Styles.Modal>
    </Styles.OfferContentModal>
  );
};

export default OfferContentModal;
