import styled from "styled-components";
import border from "@src/design-tokens/border";
import fontWeight from "@src/design-tokens/font-weight";
import padding from "@src/design-tokens/padding";

export const Wrapper = styled.div`
  background-color: var(--accent-10);
  border-radius: ${border.radius.none};
  color: var(--main-text-color);
  font-size: 1.25rem;
  font-weight: ${fontWeight.medium};
  padding: ${padding.p4} ${padding.p6};
  text-align: center;
`;

export const CaptionText = styled.div``;
