import * as Styles from "./styles";

interface Props {
  description: string;
  image: string;
  onClick: () => void;
  walletName: string;
}

const WalletProviderButton = ({
  description,
  image,
  onClick,
  walletName,
}: Props): JSX.Element => {
  return (
    <Styles.Wrapper onClick={() => onClick()}>
      <div>
        <Styles.ImageWrapper>
          <Styles.Image src={image} />
        </Styles.ImageWrapper>
      </div>
      <div>
        <Styles.ProviderName>{walletName}</Styles.ProviderName>
        <Styles.Description>{description}</Styles.Description>
      </div>
    </Styles.Wrapper>
  );
};

export default WalletProviderButton;
