import { DynamicButton } from "./style";

type Props = {
  href: string;
  text: string;
  hoverText: string | undefined;
};
const ActionButton = ({ href, text, hoverText }: Props): JSX.Element => {
  return (
    <DynamicButton className={hoverText ? "has-hover-text" : ""} href={href}>
      <span className="button-text">{text}</span>
      {hoverText && <span className="hover-text">{hoverText}</span>}
    </DynamicButton>
  );
};

export default ActionButton;
