const margin = {
  m0: "0",
  m1: "0.25rem" /* 4px */,
  m2: "0.5rem" /* 8px */,
  m3: "1rem" /* 16px */,
  m4: "1.5rem" /* 24px */,
  m5: "2rem" /* 32px */,
  m6: "2.5rem" /* 40px */,
};

export default margin;
