import { BrowserRouter, Switch, Route } from "react-router-dom";
import CheckoutNewPage from "@src/pages/checkout/new";
import ScrollToTop from "@src/components/shared/scroll-to-top";
import CheckoutSuccessPage from "@src/pages/checkout/success";
import OfferShowPage from "@src/pages/offers/show";
import OrderShowPage from "@src/pages/orders/show";
import CheckoutMoonpayPage from "@src/pages/checkout/moonpay";
import OrdersListPage from "@/src//pages/orders/list";
import DropShowPage from "@/src//pages/drops/show";
import RootPage from "@/src//pages/root";
import Faqs from "@/src//pages/faqs";

export const ROUTES = {
  root: "/",
  checkoutAddFunds: "/checkout-add-funds/:orderId",
  checkoutNew: "/checkout-new/:orderId",
  checkoutSuccess: "/checkout-success/:transactionHash",
  drop: "/drop",
  faqs: "/faqs",
  offerShow: "/offers/:id",
  orderList: "/orders",
  orderShow: "/orders/:id",
};

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path={ROUTES.orderList}>
            <OrdersListPage />
          </Route>
          <Route path={ROUTES.checkoutAddFunds}>
            <CheckoutMoonpayPage />
          </Route>
          <Route path={ROUTES.checkoutNew}>
            <CheckoutNewPage />
          </Route>
          <Route path={ROUTES.checkoutSuccess}>
            <CheckoutSuccessPage />
          </Route>
          <Route path={ROUTES.drop}>
            <DropShowPage />
          </Route>
          <Route path={ROUTES.faqs}>
            <Faqs />
          </Route>
          <Route path={ROUTES.offerShow}>
            <OfferShowPage />
          </Route>
          <Route path={ROUTES.orderShow}>
            <OrderShowPage />
          </Route>
          <Route path={ROUTES.root}>
            <RootPage />
          </Route>
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Router;
