import { ReactElement } from "react";
import { Hero, FlexHero, HeroImageWrapper } from "./styles";

type Props = {
  image: string;
  description: string | ReactElement;
  title: string;
  subtitle: string | ReactElement;
  action: ReactElement;
};

export const HeroSection = ({
  title,
  subtitle,
  description,
  image,
  action,
}: Props): JSX.Element => {
  return (
    <div id="topGrab">
      <Hero className="hero-section">
        <div className="container">
          <FlexHero>
            <HeroImageWrapper>
              <img src={image} alt="Artist Preview" />
            </HeroImageWrapper>
            <div className="hero-title">
              <h2>{title}</h2>
              <h5>{subtitle}</h5>
              <div className="description">{description}</div>
              {action}
            </div>
          </FlexHero>
        </div>
      </Hero>
    </div>
  );
};
