import WalletBloctoButton from "./provider-button/blocto-button";
import WalletMetaMaskButton from "./provider-button/meta-mask-button";
import * as Styles from "./styles";

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const WalletConnectModal = ({ isVisible, onClose }: Props): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Styles.ModalContent show={isVisible} onHide={onClose}>
        <Styles.TitleWrapper>
          <Styles.Title>Connect your wallet</Styles.Title>
          <Styles.TitleUnderline />
        </Styles.TitleWrapper>
        <Styles.WalletsListWrapper>
          <Styles.WalletsList>
            <WalletMetaMaskButton />
            <WalletBloctoButton />
          </Styles.WalletsList>
        </Styles.WalletsListWrapper>
      </Styles.ModalContent>
    </Styles.Wrapper>
  );
};

export default WalletConnectModal;
