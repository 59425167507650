import { Col, Row, Button } from "react-bootstrap";
import { Drop } from "@src/core/models/drop";
import { Offer } from "@src/core/models/offer";
import { useContext } from "react";
import * as Styles from "./styles";
import { DropContext } from "@/src/core/contexts/drop";

interface Props {
  drop: Drop;
  offers: Offer[];
  onClick: () => void;
}

const DropPromotionBanner = ({ drop, offers, onClick }: Props): JSX.Element => {
  const { dropIsActive, dropIsExpired } = useContext(DropContext);

  const formatVerticalMessage = (): string => {
    if (dropIsExpired) {
      return "Expired";
    }

    if (dropIsActive) {
      return "Live now";
    }

    return "Arriving soon";
  };

  return (
    <Styles.Wrapper>
      <Row>
        <Col xs={12} md={6}>
          <Styles.ContentWrapper>
            <Styles.DescriptionWrapper>
              <Styles.Title>{drop.promotionHeadline}</Styles.Title>
              <Styles.Description>
                {drop.promotionDescription}
              </Styles.Description>
              <Button onClick={() => onClick()} variant="light">
                View more
              </Button>
            </Styles.DescriptionWrapper>
            <Styles.VerticalTitleWrapper className="d-none d-lg-flex">
              <Styles.VerticalTitle>
                {formatVerticalMessage()}
              </Styles.VerticalTitle>
            </Styles.VerticalTitleWrapper>
          </Styles.ContentWrapper>
        </Col>
        <Col md={6} className="d-none d-md-block">
          <Styles.ImageWrapper imageUrl={drop.imageUrl} />
        </Col>
      </Row>
    </Styles.Wrapper>
  );
};

export default DropPromotionBanner;
