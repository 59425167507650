import styled from "styled-components";
import margin from "@src/design-tokens/margin";
import padding from "@src/design-tokens/padding";
import fontWeight from "@/src//design-tokens/font-weight";

export const EtherscanLink = styled.div`
  margin-bottom: ${margin.m6};
  margin-top: ${margin.m4};
  word-break: break-all;

  p {
    font-size: 1.1rem;
    font-weight: ${fontWeight.semibold};
  }
`;

export const CopyButton = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-right: ${margin.m2};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${padding.p6};
  & > * {
    max-width: 40rem;
  }
`;
