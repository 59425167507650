/* eslint-disable prettier/prettier */
export const artists = [
  {
    name: "Tetra",
    twitter: "https://twitter.com/TETRAGOCOMMANDO",
    description: (
      <>
        Multi-disciplinary artist from the Bronx, New York, known for their
        unique blend of mediums and styles. Born and raised in the city,
        Tetra&apos;s work often reflects the chaos and energy of their
        surroundings. They&apos;re particularly interested in exploring the
        concept of entropy, and many of their pieces are inspired by the idea of
        battling against the inherent disorder of the universe.
      </>
    ),
  },
  // {
  //   name: "3DBystander",
  //   twitter: "https://twitter.com/3DBystander",
  //   description: (
  //     <>
  //       Multi-disciplinary artist based in Fort Myers, Florida. 3D has
  //       specialized in a plethora of skills involving artistry and coordination.
  //       Bridging the gaps between various mediums such as 3D, AI, and cohesive
  //       branding elements. Utilizing his skills to bring black excellence to
  //       scenes and scenarios not often or yet introduced in the world of
  //       entertainment and art.
  //     </>
  //   ),
  // },
  {
    name: "MADWORLD",
    twitter: "https://twitter.com/madworldink",
    description: (
      <>
        A multi-disciplinary Artist from Southeast Michigan, Mad is a dynamic
        and versatile artist whose work traverses the boundary between abstract
        surrealism and contemporary art. With a deep fascination for the power
        of daydreams and the vast mystery behind the subconscious mind, Mad has
        been honing their craft since the tender age of 15, finding solace and
        expression through the therapeutic act of painting.
      </>
    ),
  },
  {
    name: "Braavi",
    twitter: "https://twitter.com/lukabraavi",
    description: (
      <>
        Braavi is a young artist born in New York City in 1999, with a love for
        storytelling and art, particularly anime, manga, western comics, and
        fantasy novels. He began his career at the age of 19, and his primary
        interests are poetry, illustration, narrative construction, and
        character design. His style and approach are influenced by animated
        series, authors, and personal experiences, focusing on Afrofuturism,
        philosophy, high fantasy, and slice of life, using symbolic imagery and
        personal experiences for dialogue. Braavi intends to focus on the
        development of personal intellectual property in the realms of fine art
        and storytelling, including comics/manga, gallery presence, publication
        of original NFTs, and art direction in multiple industries.
      </>
    ),
  },
  {
    name: "Juan",
    twitter: "https://twitter.com/theartofjuan",
    description: (
      <>
        Juan is an immensely talented illustrator based in the city of Dallas,
        Texas, with a specialization in crafting stunning environmental
        illustrations that transport viewers to new and exciting worlds. In
        addition to his exceptional skills in bringing lush, vivid environments
        to life, Juan is also highly skilled in creating engaging character
        scenarios and masterful concept paintings that capture the essence of a
        story or idea. With a keen eye for detail and a dedication to his craft,
        Juan&apos;s illustrations are truly breathtaking and transportive,
        inviting viewers to lose themselves in the captivating worlds he
        creates.
      </>
    ),
  },
  {
    name: "Dove",
    twitter: "https://twitter.com/gutterdove",
    description: (
      <>
        American contemporary artist based in Southern California, primarily
        working in the Web3 space. Dove’s work is described as surreal,
        sometimes disturbing, abrasive and dark. Her work has been compared to
        artists like Frida Kahlo, Salvador Dali, Francis Bacon and Giorgio de
        Chirico. Many of her themes revolve around loss, love and spirituality.
        Dove’s pieces are often self-portraits or re-enactments of true life
        events designed to tell her story so that the world might better
        understand her, so she might better understand herself, and to leave
        documentation of her life for centuries to come.
      </>
    ),
  },
];
