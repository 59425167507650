import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BloctoWalletContext } from "@src/core/contexts/blocto-wallet";
import {
  MetaMaskWalletContext,
  promptMetaMaskWalletConnection,
} from "@src/core/contexts/meta-mask-wallet";
import WalletConnectModal from "@src/components/wallet/connect-modal";
import * as Styles from "./styles";

const WalletOpenModalButton = (): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { metaMaskWallet } = useContext(MetaMaskWalletContext);
  const { bloctoWallet } = useContext(BloctoWalletContext);

  useEffect(() => {
    if (bloctoWallet || metaMaskWallet) setShowModal(false);
  }, [bloctoWallet, metaMaskWallet]);

  return (
    <Styles.Wrapper>
      <Button
        id={WALLET_OPEN_MODAL_BUTTON_ID}
        // onClick={() => setShowModal(!bloctoWallet && !metaMaskWallet && true)}
        onClick={() => promptMetaMaskWalletConnection()}
      >
        Connect wallet
      </Button>

      <WalletConnectModal
        isVisible={showModal}
        onClose={() => setShowModal(false)}
      />
    </Styles.Wrapper>
  );
};

export const WALLET_OPEN_MODAL_BUTTON_ID = "wallet-open-modal-button";

export default WalletOpenModalButton;
