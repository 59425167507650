import moment from "moment";
import { Badge, Button, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Offer } from "@src/core/models/offer";
import { Order, OrderStatus } from "@src/core/models/order";
import OfferThumbnail from "@src/components/offers/thumbnail";
import { FaRegCopy } from "react-icons/fa";
import * as Styles from "./styles";
import { formatEth } from "@/src/core/support/format-currency";

interface Props {
  offer: Offer;
  order: Order;
}

const OrderCard = ({ offer, order }: Props): JSX.Element => {
  const history = useHistory();

  const copyEtherscanAddress = (event: any): void => {
    event.stopPropagation();

    navigator.clipboard.writeText(formatEtherscanAddress());
  };

  const redirectToEtherscan = (): void => {
    window.open(formatEtherscanAddress(), "_blank", "noopener,noreferrer");
  };

  const formatEtherscanAddress = (): string => {
    return `${process.env.REACT_APP_ETHERSCAN_URL}/tx/${
      order.transaction ? order.transaction.transactionHash : ""
    }`;
  };

  const redirectToOrderCheckout = (): void => {
    history.push(`/checkout-new/${order.id}`);
  };

  return (
    <Styles.Wrapper
      onClick={() =>
        order.status === OrderStatus.Finished
          ? redirectToEtherscan()
          : redirectToOrderCheckout()
      }
    >
      <Card>
        <OfferThumbnail controls={false} offer={offer} previewSize="large" />
        <Card.Body>
          <Styles.TitleWrapper>
            <Card.Title>{offer.name}</Card.Title>
          </Styles.TitleWrapper>
          {renderStatusBadge(order)}
          {renderEthPrice(order)}
          <Card.Text>{formatTimestamp(order.updatedAt)}</Card.Text>
          {order.status === OrderStatus.AwatingConfirmation && (
            <Button>Resume checkout</Button>
          )}
          {order.status === OrderStatus.Finished && (
            <Styles.EtherscanLink>
              <Styles.CopyButton
                onClick={(event: any) => copyEtherscanAddress(event)}
              >
                <FaRegCopy />
              </Styles.CopyButton>
              <a
                target="_blank"
                href={formatEtherscanAddress()}
                rel="noreferrer"
              >
                {formatEtherscanAddress()}
              </a>
            </Styles.EtherscanLink>
          )}
        </Card.Body>
      </Card>
    </Styles.Wrapper>
  );
};

const formatTimestamp = (date: Date): string => {
  return moment(date).format("MMMM DD, YYYY - hh:mma +UTC");
};

const renderStatusBadge = (order: Order): JSX.Element => {
  const backgrounds: { [key: string]: string } = {};
  backgrounds[OrderStatus.AwatingConfirmation] = "secondary";
  backgrounds[OrderStatus.Failed] = "danger";
  backgrounds[OrderStatus.Finished] = "success";

  const translatedStatus: { [key: string]: string } = {};
  translatedStatus[OrderStatus.AwatingConfirmation] = "Awaiting confirmation";
  translatedStatus[OrderStatus.Failed] = "Failed";
  translatedStatus[OrderStatus.Finished] = "Owned";

  return (
    <Badge bg={backgrounds[order.status]}>
      {translatedStatus[order.status]}
    </Badge>
  );
};

const renderEthPrice = (order: Order): JSX.Element => (
  <Styles.EthPrice>{formatEth(order.price)}</Styles.EthPrice>
);

export default OrderCard;
