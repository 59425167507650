import {
  createContext,
  useState,
  Dispatch,
  ReactElement,
  useEffect,
} from "react";
import { EthereumRate } from "@src/core/models/ethereum-rate";

export interface EthereumRateContextProps {
  ethereumRate?: EthereumRate;
  setEthereumRate?: Dispatch<EthereumRate>;
}

interface EthereumRateProviderProps {
  children: ReactElement;
}

export const EthereumRateContext = createContext<EthereumRateContextProps>({});

export const EthereumRateProvider = ({
  children,
}: EthereumRateProviderProps): JSX.Element => {
  const [ethereumRate, setEthereumRate] = useState<EthereumRate>();

  useEffect(() => findCurrentRate(setEthereumRate), []);

  return (
    <EthereumRateContext.Provider value={{ ethereumRate, setEthereumRate }}>
      {children}
    </EthereumRateContext.Provider>
  );
};

const findCurrentRate = (setEthereumRate: Dispatch<EthereumRate>): void => {
  fetch(
    "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
  )
    .then((response) => response.json())
    .then((json) => {
      const ethPrice: string = json?.ethereum?.usd;

      if (!ethPrice) return;

      setEthereumRate({ currentRate: Number(ethPrice) });
    })
    .catch((error) => console.error(error));
};
