import PageLayout from "@/src/components/shared/page-layout";
import { faqsContent } from "@/src/data/static/faqsContent";

export type AccordionProps = {
  question: string;
  answer: string | JSX.Element;
  id: number;
};

const Accordion = ({ answer, question, id }: AccordionProps): JSX.Element => {
  return (
    <div className="sw-accordion-item bdr" style={{ marginBottom: "16px" }}>
      <h2 className="sw-accordion-header" id={`heading${id}`}>
        <button
          type="button"
          data-bs-toggle="collapse"
          className={`human sw-accordion-button ${id === 1 ? "" : "collapsed"}`}
          data-bs-target={`#collapse${id}`}
          aria-expanded="false"
          aria-controls={`#collapse${id}`}
        >
          {question}
        </button>
      </h2>
      <div
        id={`collapse${id}`}
        className={`sw-accordion-collapse collapse ${id === 1 ? "show" : ""}`}
        aria-labelledby={`heading${id}`}
        data-bs-parent="#sw-accordionExample"
      >
        <div className="sw-accordion-body acr">
          <div style={{ maxWidth: "1150px" }}>{answer}</div>
        </div>
      </div>
    </div>
  );
};

const Faqs = (): JSX.Element => {
  return (
    <PageLayout>
      <section className="asked-area" id="faqs" style={{ marginTop: "4rem" }}>
        <div className="container">
          <div className="ques">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="sw-accordion" id="sw-accordionExample">
            {faqsContent.map((faq, i) => {
              return (
                <Accordion
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  id={+i + 1}
                  question={faq.question}
                  answer={faq.answer}
                />
              );
            })}
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default Faqs;
