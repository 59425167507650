const formatEndpointUrl = (path: string, domain = ""): string => {
  return `${domain}/${path}`;
};

const API_DOMAINS = {
  accounts: process.env.REACT_APP_ACCOUNTS_API_URL,
  assets: process.env.REACT_APP_ASSETS_API_URL,
  addresses: undefined,
  creator: undefined,
  customer: undefined,
  drops: process.env.REACT_APP_DROPS_API_URL,
  offers: process.env.REACT_APP_OFFERS_API_URL,
  orders: process.env.REACT_APP_ORDERS_API_URL,
  transactions: process.env.REACT_APP_TRANSACTIONS_API_URL,
};

export default {
  addressFind: (id: string): string =>
    formatEndpointUrl(`/${id}`, API_DOMAINS.addresses),
  creatorFind: (): string => formatEndpointUrl(`/`, API_DOMAINS.creator),
  customerFind: (): string => formatEndpointUrl(`/`, API_DOMAINS.customer),
  dropsList: (): string => formatEndpointUrl(`/`, API_DOMAINS.drops),
  offersFind: (id: string): string =>
    formatEndpointUrl(`/${id}`, API_DOMAINS.offers),
  offersList: (): string => formatEndpointUrl(`/`, API_DOMAINS.offers),
  offersListByDrop: (dropId: string): string =>
    formatEndpointUrl(`/${dropId}/offers`, API_DOMAINS.drops),
  offersUpdate: (offerId: string): string =>
    formatEndpointUrl(`/${offerId}`, API_DOMAINS.offers),
  ordersCreate: (): string => formatEndpointUrl(`/`, API_DOMAINS.orders),
  ordersFind: (id: string): string =>
    formatEndpointUrl(`/${id}`, API_DOMAINS.orders),
  orderUpdate: (id: string): string =>
    formatEndpointUrl(`/${id}`, API_DOMAINS.orders),
  orderDelete: (id: string): string =>
    formatEndpointUrl(`/${id}`, API_DOMAINS.orders),
  ordersList: (wallet: string): string =>
    formatEndpointUrl(`/${wallet}/orders`, API_DOMAINS.accounts),
  transactionsCreate: (): string =>
    formatEndpointUrl(`/`, API_DOMAINS.transactions),
  transactionsFind: (id: string): string =>
    formatEndpointUrl(`/${id}`, API_DOMAINS.transactions),
  transactionsUpdate: (id: string): string =>
    formatEndpointUrl(`/${id}`, API_DOMAINS.transactions),
};
