import { useContext } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import {
  BloctoWalletContext,
  disconnectBloctoWallet,
} from "@src/core/contexts/blocto-wallet";
import { Wallet } from "@src/core/models/wallet";
import { truncateHashString } from "@src/core/support/hash";
import bloctoLogo from "@src/assets/images/blocto-logo.png";
import * as Styles from "./styles";

interface Props {
  wallet: Wallet;
}
const WalletDetailsBlocto = ({ wallet }: Props): JSX.Element => {
  const { setBloctoWallet } = useContext(BloctoWalletContext);

  return (
    <Styles.WalletDetailsBlocto>
      <Styles.Logo src={bloctoLogo} />
      <Styles.Id>{truncateHashString(wallet.id)}</Styles.Id>
      <Styles.LogoutButton
        onClick={() =>
          setBloctoWallet && disconnectBloctoWallet(setBloctoWallet)
        }
      >
        <FaSignOutAlt />
      </Styles.LogoutButton>
    </Styles.WalletDetailsBlocto>
  );
};

export default WalletDetailsBlocto;
