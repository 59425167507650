export function truncateHashString(
  hash: string,
  leftCharacters = 6,
  rightCharacters = 6
): string {
  return [
    hash.substring(0, leftCharacters),
    "...",
    hash.slice(hash.length - rightCharacters),
  ].join("");
}
