import Web3Modal from "web3modal";
import { ethers } from "ethers";
import BlockpartyContract from "@src/core/hardhat/artifacts/contracts/BlockpartyContract.sol/BlockpartyContract.json";
import { Dispatch, useContext, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ChainType, Wallet } from "@src/core/models/wallet";
import { BloctoWalletContext } from "@src/core/contexts/blocto-wallet";
import { MetaMaskWalletContext } from "@src/core/contexts/meta-mask-wallet";
import { Offer } from "@src/core/models/offer";
import { HttpClient } from "@src/core/api/http-client";
import endpoints from "@src/core/api/endpoints";
import { Order, OrderStatus } from "@src/core/models/order";
import { WALLET_OPEN_MODAL_BUTTON_ID } from "@src/components/wallet/open-modal-button";
import * as Styles from "./styles";

interface Props {
  offer: Offer;
}

const OfferBuyNow = ({ offer }: Props): JSX.Element => {
  const history = useHistory();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [showNetworkAlert, setShowNetworkAlert] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { bloctoWallet } = useContext(BloctoWalletContext);
  const { metaMaskWallet } = useContext(MetaMaskWalletContext);
  const currentWallet = bloctoWallet || metaMaskWallet;

  const renderPurchaseButton = (): JSX.Element => {
    return (
      <Button type="submit">
        <span>Mint Now</span>
      </Button>
    );
  };

  const renderConnectWalletButton = (): JSX.Element => {
    return (
      <Button type="button" onClick={() => triggerWalletConnectModal()}>
        <span>Connect wallet to buy now</span>
      </Button>
    );
  };

  const handleSubmit = async (event: any): Promise<void> => {
    event.preventDefault();

    if (!currentWallet) {
      return triggerWalletConnectModal();
    }

    const isMainnet = process.env.REACT_APP_IS_MAINNET || "false";
    const invalidNetworkSelected =
      JSON.parse(isMainnet) && currentWallet.chainId !== ChainType.Mainnet;

    if (invalidNetworkSelected) {
      setShowNetworkAlert(true);
      return undefined;
    }

    const completeCheckout = (transactionHash: string) => {
      history.push(`/checkout-success/${transactionHash}`);
      setShowOverlay(false);
      setShowLoadingMessage(false);
    };

    return submitOrder(
      offer,
      currentWallet,
      setShowOverlay,
      setShowLoadingMessage,
      setShowErrorMessage,
      setErrorMessage,
      completeCheckout
    );
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="align-items-center">
          <Col xs={12}>
            <Styles.HighlightWrapper>
              <p>
                NOTE: You will have 3 minutes to complete your purchase once you
                click the ‘Mint Now’ button. After 3 minutes, the NFT will be
                released for others to purchase.
              </p>
            </Styles.HighlightWrapper>
            <Form.Group>
              {!currentWallet && renderConnectWalletButton()}
              {currentWallet && renderPurchaseButton()}
            </Form.Group>
            {showErrorMessage && errorMessage && (
              <Styles.ErrorMessageWrapper>
                <span>{`${errorMessage}`}</span>
              </Styles.ErrorMessageWrapper>
            )}
          </Col>
        </Row>
      </Form>
      {showOverlay && (
        <Styles.Overlay>
          {showLoadingMessage && (
            <>
              <Styles.OverlayLoadingWrapper>
                <Spinner animation="grow" />
                <Styles.OverlayLoadingMessage>
                  Please wait a few seconds while your request is being
                  processed.
                  {/* <br />
                  <br />
                  <b>ATTENTION:&nbsp;</b>Please don&apos;t close your browser
                  <br />
                  window or navigate away while your transaction is processing.
                  <br />
                  Navigating away during this time can result in a failed
                  <br />
                  transaction or loss of funds. */}
                </Styles.OverlayLoadingMessage>
              </Styles.OverlayLoadingWrapper>
            </>
          )}
        </Styles.Overlay>
      )}
    </>
  );
};

const submitOrder = async (
  offer: Offer,
  wallet: Wallet,
  setShowOverlay: Dispatch<boolean>,
  setShowLoadingMessage: Dispatch<boolean>,
  setShowErrorMessage: Dispatch<boolean>,
  setErrorMessage: Dispatch<string>,
  onSuccess: (transactionHash: string) => void
): Promise<void> => {
  setShowOverlay(true);
  setShowLoadingMessage(true);
  setShowErrorMessage(false);

  const payload = {
    offerId: offer.id,
    wallet: wallet.id,
    price: offer.price,
    status: OrderStatus.AwatingConfirmation,
  };

  HttpClient.post(
    endpoints.ordersCreate(),
    payload,
    async (order: Order): Promise<void> => {
      try {
        if ((order.voucher as any).errorMessage)
          throw Error("Signing the minting voucher has failed");
        console.log(`Voucher created: ${JSON.stringify(order.voucher)}`);
        const web3Modal = new Web3Modal({
          network: "mumbai",
          cacheProvider: true,
        });
        console.log(`Preparing to connecto to network.`);
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        console.log(`Connected to network using Alchemy.`);
        const buyer = provider.getSigner();
        const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS || "";
        const contract = new ethers.Contract(
          contractAddress,
          BlockpartyContract.abi,
          buyer
        );
        console.log(`Transaction initiated.`);
        const voucherResponse = order.voucher;
        console.log(`Voucher information: ${voucherResponse}.`);
        const metaMaskTransaction = await contract
          .redeem(voucherResponse, {
            value: voucherResponse.price,
          })
          .catch((error: any) => {
            HttpClient.delete_item(
              endpoints.orderDelete(order.id),
              async (): Promise<void> => {
                setShowErrorMessage(true);
                setErrorMessage(error.message);
                setShowOverlay(false);
                setShowLoadingMessage(false);
              }
            );
          });
        if (metaMaskTransaction) {
          // let receipt = await provider.getTransactionReceipt(
          //   metaMaskTransaction.hash
          // );

          // let i = 1;

          // while (!receipt) {
          //   /* eslint-disable no-await-in-loop */
          //   receipt = await provider.getTransactionReceipt(
          //     metaMaskTransaction.hash
          //   );
          //   i++;

          //   if (receipt || i > 10) break;

          //   await new Promise((f) => setTimeout(f, 3000));
          // }

          // if (!(receipt as any)) {
          //   throw Error(
          //     "Transaction failed due to network congestion. This order is cancelled and you can try again."
          //   );
          // }
          console.log(
            `Transaction information: ${JSON.stringify(metaMaskTransaction)}`
          );
          // console.log(`Transaction receipt: ${JSON.stringify(receipt)}`);
          const updatedOrder = {
            ...order,
            transactionHash: metaMaskTransaction.hash,
          };

          HttpClient.put<Order>(
            endpoints.orderUpdate(order.id),
            updatedOrder,
            async (): Promise<void> => {
              onSuccess(metaMaskTransaction.hash);
            }
          ).catch((error: any) => {
            setShowErrorMessage(true);
            setErrorMessage(error);
            setShowOverlay(false);
            setShowLoadingMessage(false);
          });
        }
      } catch (error: any) {
        HttpClient.delete_item(
          endpoints.orderDelete(order.id),
          async (): Promise<void> => {
            setShowErrorMessage(true);
            setErrorMessage(error.message);
            setShowOverlay(false);
            setShowLoadingMessage(false);
          }
        ).catch((derror: any) => {
          setShowErrorMessage(true);
          setErrorMessage(derror.message);
          setShowOverlay(false);
          setShowLoadingMessage(false);
        });
      }
    }
  );
};

const triggerWalletConnectModal = () => {
  const $openModalButton = document.getElementById(WALLET_OPEN_MODAL_BUTTON_ID);

  $openModalButton?.click();
};

export default OfferBuyNow;
