import styled from "styled-components";
import color from "@src/design-tokens/color";
import margin from "@src/design-tokens/margin";

export const Id = styled.div``;

export const Logo = styled.img`
  height: 2rem;
  margin-right: ${margin.m2};
`;
export const LogoutButton = styled.div`
  align-items: center;
  color: ${color.neutral.medium};
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  justify-content: center;
  margin-left: ${margin.m3};
`;

export const WalletDetailsBlocto = styled.div`
  align-items: center;
  color: white;
  display: flex;
`;
