/* eslint-disable jsx-a11y/media-has-caption */
import { Helmet } from "react-helmet";
import { Offer } from "@src/core/models/offer";
import * as Styles from "./styles";

interface Props {
  controls?: boolean;
  muted?: boolean;
  offer: Offer;
  previewSize?: "large" | "small";
}

const OfferThumbnail = ({
  controls,
  muted,
  offer,
  previewSize,
}: Props): JSX.Element => {
  const assetUrl =
    previewSize === "large" ? offer.previewUrlLarge : offer.previewUrlSmall;

  return (
    <>
      <Helmet>
        <meta property="og:image" content={assetUrl} />
      </Helmet>
      <Styles.OfferThumbnail>
        {offer.mediaMimeType === "video/mp4" ? (
          <video controls={controls} autoPlay muted={muted}>
            <source src={assetUrl} type="video/mp4" />
          </video>
        ) : (
          <Styles.OfferThumbnail>
            <img src={assetUrl} alt={`${offer.name} by ${offer.artist}`} />
          </Styles.OfferThumbnail>
        )}
      </Styles.OfferThumbnail>
    </>
  );
};

OfferThumbnail.defaultProps = {
  controls: true,
  muted: true,
  previewSize: "small",
};

export default OfferThumbnail;
