import { Base } from "@src/core/models/base";

export interface BasePayload<T = any> {
  attributes: T;
  id: string;
  type: string;
}

function map<TModel extends Base>(payload: BasePayload): TModel {
  const { attributes, id } = payload;

  return {
    ...attributes,
    id,
  };
}

function mapMany<TModel extends Base>(payload: BasePayload[]): TModel[] {
  return payload.map((resource: BasePayload) => {
    return map(resource as BasePayload);
  });
}

export const PayloadMapper = {
  map,
  mapMany,
};
