/* eslint-disable react/no-array-index-key */
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState, ReactElement } from "react";
import { renderToString } from "react-dom/server";
import Masonry from "react-masonry-component";
import goose1Preview from "@src/assets/images/Goose-NFT-Preview.gif";
import goose2Preview from "@src/assets/images/Goose2Preview.jpeg";
import EasyTimer from "easytimer.js";
import { HeroSection } from "@/src/components/HeroSection";
import PageLayout from "@/src/components/shared/page-layout";
import ActionButton from "@/src/components/ActionButton";
import { timezoneOffset } from "@/src/core/support/timezone-offset";
import { artists } from "@/src/data/static/artistsContent";
import { ArtistSection } from "@/src/components/ArtistSection";
import { HighlightWrapper } from "@/src/components/offers/details/call-to-action/buy-now/styles";
import { dropContent } from "@/src/data/static/dropContent";

const RootPage = (): JSX.Element => {
  const images = [goose1Preview, goose2Preview];
  const [dropIsLive, setDropIsLive] = useState(false);
  const [anySoldOut, setAnySoldOut] = useState(false);
  const [masonryItems, setMasonryItems] = useState<HTMLElement[]>([]);
  const isLive = (startDate: Date) => {
    return new Date(startDate).valueOf() <= new Date().valueOf();
  };

  const masonryContainer = useRef<HTMLDivElement | null>(null);

  let getEthereumPrice: number | undefined;
  let grabListJsonData = "";
  let isShow = false;

  function isEqual(start: Date, end: Date) {
    return end.valueOf() === start.valueOf();
  }

  const setCountDown = (
    startDate: Date,
    index: number
  ): boolean | undefined => {
    const tempStartDate = timezoneOffset(new Date(startDate));

    const getCurrentDate = () => new Date().toUTCString();

    const hideCountdown = tempStartDate.getTime() <= new Date().getTime();

    if (hideCountdown) {
      return true;
    }

    const gribTimer = new EasyTimer();
    const gribCountdownContainer = document.getElementById(
      `grabListCountDown${index > -1 ? index : "Top"}`
    );

    const currentDate = new Date(getCurrentDate());

    const distance = tempStartDate.valueOf() - currentDate.valueOf();

    const daysLeft = Math.floor(distance / (1000 * 60 * 60 * 24));

    const hoursLeft = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    const minutesLeft = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const secondsLeft = Math.floor((distance % (1000 * 60)) / 1000);

    if (!gribCountdownContainer) return undefined;

    // debugger;

    gribTimer.start({
      countdown: true,
      startValues: {
        days: daysLeft,
        seconds: secondsLeft,
        minutes: minutesLeft,
        hours: hoursLeft,
      },
    });

    const displayedValues: any = [
      daysLeft ? "days" : undefined,
      "hours",
      "minutes",
      "seconds",
    ].filter(Boolean);

    gribCountdownContainer.innerHTML = gribTimer
      .getTimeValues()
      .toString(displayedValues)
      .replaceAll(":", " : ");

    gribTimer.addEventListener("secondsUpdated", (_e) => {
      gribCountdownContainer.innerHTML = gribTimer
        .getTimeValues()
        .toString(displayedValues)
        .replaceAll(":", " : ");
    });

    gribTimer.addEventListener("targetAchieved", (_e) => {
      function delay(time: number) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }
      delay(1000).then(() => grabOffersShow());
    });

    return undefined;
  };

  function addGridItem(item: string) {
    const htmlListItem = new DOMParser().parseFromString(item, "text/html");

    const listItem = htmlListItem.getElementsByClassName("masonry-item");
    const masonryItem = listItem[0] as HTMLElement;

    if (!listItem[0]) {
      return;
    }

    const newItems = [...masonryItems, masonryItem];

    setMasonryItems(newItems);
  }

  function grabOffersShow() {
    if (isShow) {
      const ethereumUsd: any = getEthereumPrice;
      const json: any = grabListJsonData;

      const getDate = (date: Date) => {
        const today = new Date(date);

        return new Date(
          `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
        );
      };

      const masonryElement = masonryContainer.current?.children[0];
      const grabListElement = document.getElementById("grabList");
      const topGrabElement = document.getElementById("topGrab");

      if (!grabListElement) return;
      if (!topGrabElement) return;

      let grabList = "";
      let isBlur = "";
      let showCountdown = "";
      let isShowTopGrab = false;
      let showTopGrab: string | JSX.Element = "";
      let tempDate: Date = new Date();
      let finalTopGridDate: Date = tempDate;

      const dates = [];

      const offerCount = json.body.data.length;

      for (let i = 0; i < offerCount; i++) {
        if (!isLive(json.body.data[i].attributes.startsAt)) {
          dates.push(new Date(json.body.data[i].attributes.startsAt));
        } else {
          setDropIsLive(true);
        }
      }

      const showTopGrideDate = new Date(
        Math.min.apply(
          null,
          dates.map((item) => item.valueOf())
        )
      );

      const availableOffers: any = [];
      const soldOutOffers: any = [];

      let grabListItemString = "";

      for (let i = 0; i < offerCount; i++) {
        if (json.body.data[i].attributes.availableCount > 0) {
          availableOffers.push(json.body.data[i]);
        } else {
          soldOutOffers.push(json.body.data[i]);
        }
      }

      const offers = availableOffers.concat(soldOutOffers);

      for (let i = 0; i < offerCount; i++) {
        tempDate = new Date(offers[i].attributes.startsAt);

        if (!isLive(timezoneOffset(offers[i].attributes.startsAt))) {
          isBlur = "blur-pro";
          showCountdown = "showCountdown";

          if (isEqual(showTopGrideDate, tempDate)) {
            if (!isShowTopGrab) {
              finalTopGridDate = tempDate;
              showTopGrab = renderToString(
                <>
                  {renderHero(
                    <div className="time-area">
                      <div>
                        <div
                          className="countdown"
                          id="grabListCountDownTop"
                          data-show-on-upcoming-drop
                        />
                      </div>
                    </div>
                  )}
                </>
              );
              topGrabElement.innerHTML = showTopGrab;
              isShowTopGrab = true;
            }
          }
        } else {
          isBlur = "";
          showCountdown = "";

          if (isEqual(getDate(new Date()), getDate(tempDate))) {
            showTopGrab = renderToString(
              <>
                {renderHero(
                  <ActionButton
                    href="#shop"
                    text="Explore the drop"
                    hoverText={undefined}
                  />
                )}
              </>
            );
            topGrabElement.innerHTML = showTopGrab;
            isShowTopGrab = true;
          }

          setDropIsLive(true);
        }

        const offerImage = images.find((item) => {
          return item.indexOf(offers[i].attributes.previewUrlSmall) > -1;
        });

        const offerMediaType = offers[i].attributes.mediaMimeType;

        let mintButtonText = "Mint Now";
        const soldOut = offers[i].attributes.availableCount === "0";
        if (soldOut) {
          mintButtonText = "Sold Out!";
          setAnySoldOut(true);
        }

        const previewMedia =
          offerMediaType === "video/mp4"
            ? `<video autoplay muted loop><source src='${
                offerImage !== undefined
                  ? offerImage
                  : offers[i].attributes.previewUrlSmall
              }' type='video/mp4'></video>`
            : `<img src='${offers[i].attributes.previewUrlSmall}' alt=''>`;

        grabListItemString =
          `<div class="masonry-item ${
            showCountdown || ""
          }"><div class="girbBox ${isBlur}${
            soldOut ? " disabled" : ""
          }" data-grab-box >` +
          `<a href="${process.env.REACT_APP_DOMAIN}/offers/${offers[i].attributes.offerId}" class="innerbox1">` +
          `<div class='preview-media'>${previewMedia}</div>` +
          `</a>` +
          `<div class="title-box"><table><tr class="firstRow">` +
          `<th class="f-text">${offers[i].attributes.name}</th>` +
          `<td class="n-text" rowspan="2">${
            offers[i].attributes.totalItems -
            offers[i].attributes.availableCount
          }/${offers[i].attributes.totalItems}</td>` +
          `</tr></table><table><tr><td class="artist">by <span>${offers[i].attributes.artist}</span></td></tr></table><hr><table><tr><td class="price" colspan="2">Price</td></tr><tr><td class="td-text" colspan="2">${json.body.data[i].attributes.price}ETH  &nbsp; <span class="n-text">` +
          `<span class="usd">
          ${
            ethereumUsd
              ? formatUsd(offers[i].attributes.price * ethereumUsd)
              : ""
          }</span>` +
          `</span></td></tr></table>` +
          `<a href="${process.env.REACT_APP_DOMAIN}/offers/${offers[i].attributes.offerId}"	class="bid" style="text-decoration: none;">` +
          `<span> ${mintButtonText} </span></a></div></div><div class="countDownOverlay" ><div class="droppingIn">DROPPING IN</div><div id="grabListCountDown${i}" class='grabListCountdown'></div></div></div>`;

        grabList = `${grabList}${grabListItemString}`;

        if (!masonryElement) {
          return;
        }

        masonryElement.innerHTML = grabList;

        addGridItem(grabListItemString);
      }

      if (!masonryElement) {
        return;
      }

      masonryElement.innerHTML = grabList;

      addGridItem(grabListItemString);

      for (let i = 0; i < json.body.data.length; i++) {
        if (!isShowTopGrab) {
          showTopGrab = renderToString(
            <>
              {renderHero(
                <ActionButton
                  href="#shop"
                  text="Explore the drop"
                  hoverText={undefined}
                />
              )}
            </>
          );
          topGrabElement.innerHTML = showTopGrab;
          isShowTopGrab = true;
        }
      }

      isShowTopGrab = false;

      for (let i = 0; i < json.body.data.length; i++) {
        setCountDown(json.body.data[i].attributes.startsAt, i);
        if (
          isEqual(
            finalTopGridDate,
            new Date(json.body.data[i].attributes.startsAt)
          )
        ) {
          setCountDown(json.body.data[i].attributes.startsAt, -1);
          isShowTopGrab = true;
        }
      }
    }
  }

  const getEthereumUsd = () => {
    fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
    )
      .then((response) => response.json())
      .then((json) => {
        getEthereumPrice = json.ethereum.usd;
      })
      .catch((error) => console.error(error));
  };

  const grabOffers = () => {
    // fetch(`${process.env.REACT_APP_OFFERS_API_URL}/`)
    fetch(
      `${process.env.REACT_APP_DROPS_API_URL}/${process.env.REACT_APP_MAIN_DROP_ID}/offers`
    )
      .then((response) => response.json())
      .then((json) => {
        grabListJsonData = json;
        isShow = true;
        grabOffersShow();
      })
      .catch((error) => console.error(error));
  };

  function formatUsd(val: number) {
    const formattedPrice = new Intl.NumberFormat("en-US", {
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
    }).format(val);
    return formattedPrice;
  }

  useEffect(() => {
    getEthereumUsd();
    grabOffers();
  }, []);

  const metaImage = `${process.env.REACT_APP_DOMAIN}${dropContent.image}`;

  return (
    <PageLayout>
      <>
        <Helmet>
          <title>{dropContent.title}</title>
          <meta
            name="description"
            content={dropIsLive ? dropContent.description : "Coming Soon"}
          />
          <meta property="og:title" content={dropContent.title} />
          <meta
            property="og:description"
            content={dropIsLive ? dropContent.description : "Coming Soon"}
          />
          <meta property="og:image" content={metaImage} />
        </Helmet>
        {renderHero(<></>)}
        <section id="shop" className="collection-area">
          <div className="container">
            <div className="collection-title">
              <h2 style={{ paddingLeft: "0" }}>Shop the Collection</h2>
              <p className="content1">
                An assortment of works curated through PRJKT2700 presenting a
                plethora of vibrant perspectives on what defines a good time.
              </p>
            </div>
            <div className="masonry-container" ref={masonryContainer}>
              <Masonry
                className="masonry-grid"
                options={{ horizontalOrder: true }}
              />
            </div>
            <div id="grabList" />
            {anySoldOut && (
              <HighlightWrapper className="under-offers">
                <p>
                  NOTE: If an item appears sold out, be sure to check back
                  periodically as items may become available again in the event
                  of a failed or abandoned transaction.
                </p>
              </HighlightWrapper>
            )}
          </div>
        </section>
        <section className="about-area" id="about">
          <div className="container about-container">
            <div className="about-title-2">
              <h2>About the Collection</h2>
              <p className="content1">
                The prompt for this collection of works is &ldquo;What does a
                good time look like to you?&rdquo; and each piece offers a
                unique and imaginative interpretation of the theme. From vibrant
                abstract paintings to playful illustrations, &ldquo;At the
                Function&rdquo; showcases a diverse range of styles and mediums.
                The artworks are infused with a sense of energy and joy,
                capturing the essence of what it means to have a good time. As
                you explore this curated art drop, you&apos;ll be transported to
                different moments of fun and celebration. Some pieces depict
                raucous parties and dancing, while others portray quiet moments
                of contentment and relaxation. Each artwork invites you to
                reflect on your own idea of a good time and to appreciate the
                beauty and creativity of the artists&apos; perspectives.
                Overall, &ldquo;At the Function&rdquo; is a vibrant and dynamic
                collection of art that celebrates the joy and excitement of
                life&apos;s most memorable moments. Whether you&apos;re an art
                enthusiast or simply looking for inspiration, this curated drop
                was created to delight and inspire.
              </p>
            </div>
          </div>
        </section>
        {artists &&
          artists.map((artist, i) => {
            return (
              <ArtistSection
                key={artist.name}
                name={artist.name}
                twitter={artist.twitter}
                id={i}
                description={artist.description}
              />
            );
          })}
        {/* <Link to={`/drops/${uuid()}`}>onClick here to see a sample drop</Link> */}
      </>
    </PageLayout>
  );
};

const AtTheFunctionDescription = (): ReactElement => {
  return (
    <>
      <div
        style={{
          marginBottom: "8px",
          color: "var(--main-text-color)",
          fontSize: "16px",
          opacity: ".9",
        }}
      >
        Featuring art from:
      </div>
      {artists && (
        <ul className="artists" key="artists">
          {artists.map((artist, i) => {
            return (
              <li key={artist.name}>
                <a href={`#artist_${i}`} style={{ textDecoration: "none" }}>
                  {artist.name}
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

const AtTheFunctionSubtitle = (): ReactElement => {
  const subtitleStyle = {
    display: "flex",
    width: "100%",
    gridGap: "8px",
  };

  return (
    <div style={subtitleStyle}>
      <span style={{ display: "inline-block" }}>
        The essence of joy curated by
      </span>{" "}
      <a
        href="https://twitter.com/PRJKT2700"
        target="_blank"
        rel="noreferrer"
        style={{
          display: "inline-block",
          fontSize: "20px",
          lineHeight: "30px",
          width: "auto",
        }}
      >
        PRJKT2700
      </a>
    </div>
  );
};

const renderHero = (action: ReactElement): JSX.Element => {
  return (
    <HeroSection
      action={action}
      title="At the Function"
      subtitle={<AtTheFunctionSubtitle />}
      description={<AtTheFunctionDescription />}
      image={dropContent.image}
    />
  );
};

export default RootPage;
