import { Image } from "react-bootstrap";
import styled from "styled-components";
import border from "@src/design-tokens/border";
import color from "@src/design-tokens/color";
import fontWeight from "@src/design-tokens/font-weight";
import margin from "@src/design-tokens/margin";
import { CountdownContainer as DateCountdownContainer } from "@src/components/shared/date-countdown/styles";
import { Wrapper as DropCountdownWrapper } from "@src/components/drops/countdown/styles";

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: ${margin.m4};
`;

export const CreatorTag = styled.div`
  align-items: center;
  display: flex;

  &:after {
    border-right: 1px solid ${color.neutral.light};
    content: " ";
    height: 2rem;
    margin-left: ${margin.m4};
    margin-right: ${margin.m4};
    width: 1px;
  }
`;

export const CreatorImage = styled(Image)`
  height: 3.6rem;
  margin-right: ${margin.m3};
  width: 3.6rem;
`;

export const ImageWrapper = styled.div`
  margin-bottom: ${margin.m4};
`;

export const OfferImage = styled.img`
  border-radius: ${border.radius.none};
  width: 100%;
`;

export const TagLabel = styled.div`
  font-size: 0.85rem;
  font-weight: ${fontWeight.medium};
  line-height: 1.25;
`;

export const TagText = styled.div`
  font-size: 1.2rem;
  font-weight: ${fontWeight.semibold};
`;

export const PriceTag = styled.div`
  ${TagText} {
    white-space: nowrap;
  }
`;

export const CallToActionWrapper = styled.div`
  margin-top: ${margin.m4};
`;

export const RemainingLabel = styled.div`
  font-weight: ${fontWeight.medium};
  margin-top: ${margin.m2};
`;

export const TagsWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${margin.m4};
  margin-bottom: ${margin.m2};
`;

export const Title = styled.div`
  font-size: 1.8rem;
  font-weight: ${fontWeight.bold};
  margin-bottom: ${margin.m3};
  font-family: var(--display-font);
`;

export const Wrapper = styled.div`
  margin-top: 60px;
  font-family: var(--body-font);
  ${DateCountdownContainer} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${DropCountdownWrapper} {
    & + div {
      ${OfferImage} {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
`;
