import * as Styles from "./styles";

interface Props {
  text: string;
}

const TitleSection = ({ text }: Props): JSX.Element => {
  return <Styles.Title>{text}</Styles.Title>;
};

export default TitleSection;
