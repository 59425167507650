import DateCountdown from "@src/components/shared/date-countdown";
import * as Styles from "./styles";

interface Props {
  targetDate: Date;
  onComplete: () => void;
}

const DropEndCountdown = ({ targetDate, onComplete }: Props): JSX.Element => {
  return (
    <Styles.Container>
      <DateCountdown
        captionText="Time remaining"
        onComplete={onComplete}
        targetDate={targetDate}
      />
    </Styles.Container>
  );
};

export default DropEndCountdown;
