import * as Styles from "./styles";

const HorizontalLine = (): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Styles.Line />
    </Styles.Wrapper>
  );
};

export default HorizontalLine;
