import * as Styles from "./styles";

interface Props {
  alignment?: "center" | "left" | "right";
  text: string;
}

const TitleSubSection = ({ alignment, text }: Props): JSX.Element => {
  return (
    <Styles.TitleSubsection alignment={alignment}>
      <Styles.Title>{text}</Styles.Title>
      <Styles.Decoration />
    </Styles.TitleSubsection>
  );
};

TitleSubSection.defaultProps = { alignment: "left" };

export default TitleSubSection;
