import styled from "styled-components";

export const ArtistSectionWrapper = styled.section`
  width: 100%;
  margin: 120px 0;
  .artist-title {
    h2 {
      font-family: var(--display-font-2);
      font-size: 32px;
      line-height: 32px;
      letter-spacing: 0.01em;
    }
    p {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.05em;
      color: var(--main-text-color);
      font-family: var(--body-font);
      a {
        font-size: 18px;
        line-height: 159%;
        // text-decoration: none;
        color: var(--accent-80);
        &:hover {
          color: var(--accent-color);
        }
      }
    }
    .name-row {
      display: flex;
      grid-gap: 16px;
      .link {
        width: 24px;
        height: 24px;
        svg {
          width: 100%;
          height: 100%;
        }
        span {
          text-indent: -9999px;
          display: inline-block;
        }
      }
    }
  }
`;
