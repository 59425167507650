import { Image } from "react-bootstrap";
import styled from "styled-components";
import border from "@src/design-tokens/border";
import color from "@src/design-tokens/color";
import fontWeight from "@src/design-tokens/font-weight";
import margin from "@src/design-tokens/margin";
import padding from "@src/design-tokens/padding";

export const CreatorImage = styled(Image)`
  height: 7rem;
  width: 7rem;
`;

export const Id = styled.div`
  border: 1px solid ${color.neutral.light};
  border-radius: ${border.radius.none};
  color: ${color.primary.light};
  font-size: 0.9rem;
  font-weight: ${fontWeight.medium};
  margin-bottom: ${margin.m5};
  padding: ${padding.p2} ${padding.p5};
`;

export const ImageWrapper = styled.div`
  margin-bottom: ${margin.m3};
`;

export const Name = styled.div`
  font-size: 1.4rem;
  font-weight: ${fontWeight.semibold};
`;

export const Role = styled.p`
  margin-bottom: ${margin.m3};
`;

export const Wrapper = styled.div`
  .card-body {
    align-items: center;
    display: flex;
    flex-flow: column;
    padding-bottom: ${padding.p6};
    padding-top: ${padding.p6};
  }
`;
