import styled from "styled-components";

export const Image = styled.img`
  width: 100%;
  transition: 0.7s;
`;

export const OfferThumbnail = styled.div`
  aspect-ratio: 1 / 1;
  overflow: hidden;
  video {
    width: 100%;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: 0 auto;
  }

  &:hover {
    ${Image} {
      transform: scale(1.03);
      transition: 0.7s;
    }
  }
`;
