import React from "react";
import ReactDOM from "react-dom";
import { GlobalProviderWrapper } from "./core/contexts/global";
import Router from "./core/infrastructure/router";

ReactDOM.render(
  <React.StrictMode>
    <GlobalProviderWrapper>
      <Router />
    </GlobalProviderWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);
