import styled from "styled-components";
import { OfferThumbnail } from "@src/components/offers/thumbnail/styles";

export const ImageOverlay = styled.div`
  align-items: center;
  background-color: #000;
  color: var(--main-text-color);
  cursor: pointer;
  display: flex;
  font-size: 3rem;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 1000;
  animation: fade 1s linear;
  transition: opacity 0.3s linear;
`;

export const OfferContentPreview = styled.div`
  position: relative;

  ${OfferThumbnail} {
    aspect-ratio: auto;
    align-items: start;
    margin: 0;
  }

  &:hover {
    ${ImageOverlay} {
      transition: opacity 0.3s linear;
      opacity: 0.8;
    }
  }
`;
