import { useEffect, ReactElement } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {
  children: ReactElement;
}

const ScrollToTop = ({ children }: ScrollToTopProps): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
