import { Wallet } from "@src/core/models/wallet";
import { truncateHashString } from "@src/core/support/hash";
import metaMaskLogo from "@src/assets/images/meta-mask-logo.svg";
import * as Styles from "./styles";

interface Props {
  wallet: Wallet;
}
const WalletDetailsMetaMask = ({ wallet }: Props): JSX.Element => {
  return (
    <Styles.WalletDetailsMetaMask>
      <Styles.Logo src={metaMaskLogo} />
      <Styles.Id>{truncateHashString(wallet.id)}</Styles.Id>
    </Styles.WalletDetailsMetaMask>
  );
};

export default WalletDetailsMetaMask;
