import styled from "styled-components";

export const Links = styled.div`
  display: flex;
  gap: 16px;
  margin: 0;
  justify-content: center;
  margin-top: 8px;
  @media (min-width: 992px) {
    margin-top: 0;
    justify-content: ${(props) => props.style?.content};
  }
  a {
    font-size: 24px;
    color: ${(props) =>
      props.style?.color === "white"
        ? "var(--main-text-color)"
        : props.style?.color};
    transition: transform 0.2s ease-in-out;
    transform: scale(1);
    transform-origin: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      transform: scale(1.2);
    }
    span {
      text-decoration: none;
      display: block;
      position: absolute;
      text-indent: -9999px;
    }
  }
`;
