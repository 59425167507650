/* eslint-disable prettier/prettier */
// Ethereum Template
export const faqsContent = [
  {
    question: "How many NFTs are there and how much will they cost?",
    answer:
      "The collection consists of 6 different pieces, each available in editions of 10, and individually priced between 0.05 ETH and 0.07 ETH.",
  },
  {
    question: "Will the NFTs be sold on open marketplaces?",
    answer: (
      <>
        The initial sale will take place solely on this site. Please confirm
        you&apos;re on the correct site before connecting your wallet. If the
        drop is sold out or you want a specific edition number you&apos;ll be
        able to purchase on the secondary market of your choice that supports
        trading of Ethereum-based NFTs, such as the{" "}
        <b>
          <a
            href="https://www.nft.org"
            target="_blank"
            rel="noreferrer"
            style={{ whiteSpace: "nowrap" }}
          >
            NFT.org DEX
          </a>
          .
        </b>
      </>
    ),
  },
  {
    question: "How do I purchase an NFT?",
    answer: (
      <>
        <ul>
          <li>
            Download and install the MetaMask Chrome Extension or mobile app*
          </li>
          <li>
            Fund your MetaMask wallet with ETH if you have not already done so.
          </li>
          <li>Connect to our mint site with your MetaMask wallet.</li>
          <li>
            Click Mint Now and make sure to sign the transaction that pops up
            from MetaMask to complete the transaction and mint your NFT.
          </li>
        </ul>
        <br />
        *NOTE: For an optimal checkout experience, we highly recommend
        completing your purchase from a desktop browser that supports the
        MetaMask wallet extension such as Google Chrome. If you would like to
        purchase on mobile, please download the MetaMask wallet mobile
        application, connect your wallet, and navigate to this site via the
        in-app MetaMask browser to complete your purchase.
      </>
    ),
  },
  {
    question: "How to download and install MetaMask on desktop (recommended)",
    answer: (
      <>
        To set up a MetaMask wallet follow the steps below:
        <br />
        <br />
        <ul>
          <li>
            Visit{" "}
            <a href="https://MetaMask.io" target="_blank" rel="noreferrer">
              MetaMask.io
            </a>{" "}
            and select &apos;Download&apos;. Select how you want to install the
            program, on Chrome, iOS, or Android (Chrome browser extension is
            recommended) extension is recommended)
          </li>
          <li>
            Once you have installed MetaMask, set your password. Choose a strong
            password and protect it
          </li>
          <li>
            IMPORTANT: You will then see your 12-word seed phrase. This is a
            secret recovery key that can be used to restore your account if you
            get locked out. Write this seed phrase down on paper and keep it
            safe! If you lose this you are at risk of permanently losing access
            to your wallet and the contents stored within it. MetaMask is a
            third-party application and Blockparty is not responsible for nor
            capable of recovering any NFTs or funds custodied in a MetaMask
            wallet.
          </li>
          <li>
            Once you have recorded your seed phrase you will be ready to use
            MetaMask!
          </li>
        </ul>
      </>
    ),
  },
  {
    question: "How to download and install MetaMask on mobile",
    answer: (
      <>
        To set up a MetaMask wallet follow the steps below: <br />
        <br />
        <ul>
          <li>
            Visit the Google Playstore on your Android device or the App Store
            on your iOS device.
          </li>
          <li>Search for MetaMask and download the mobile app.</li>
          <li>
            Once you have installed MetaMask, set your password. Choose a strong
            password and protect it.
          </li>
          <li>
            IMPORTANT: You will then see your 12-word seed phrase. This is a
            secret recovery key that can be used to restore your account if you
            get locked out. Write this seed phrase down on paper and keep it
            safe! If you lose this you are at risk of permanently losing access
            to your wallet and the contents stored within it. MetaMask is a
            third-party application and Blockparty is not responsible for nor
            capable of recovering any NFTs or funds custodied in a MetaMask
            wallet.
          </li>
          <li>
            Once you have recorded your seed phrase you will be ready to use
            MetaMask!
          </li>
        </ul>
        <br />
        *NOTE: If you want to use an existing MetaMask wallet on your mobile
        app, select ‘Import Wallet’ and use the 12-word seed phrase from your
        original wallet to add it in the mobile app.
      </>
    ),
  },
  {
    question: "How to fund your MetaMask wallet",
    answer: (
      <>
        Next, you&apos;ll need to add funds so you can make your purchase. If
        you do not have crypto in your wallet, MoonPay enables users in most
        regions to buy ETH directly in your MetaMask wallet with a credit or
        debit card following the steps below. NY residents skip to the following
        section for more info on how to purchase ETH.
        <br />
        <br />
        <ul>
          <li>
            Open your MetaMask wallet and ensure that you are connected to
            Ethereum Main Network
          </li>
          <li>Select the ‘Buy&apos; button</li>
          <li>Scroll down to Moonpay and select ‘Continue to MoonPay&apos;</li>
          <li>
            Choose the ‘Currency&apos; and ‘Amount&apos;. The amount of ETH you
            buy will depend on the price of the NFT. Make sure to add a little
            bit more to cover the gas fees associated with processing
            transactions on the blockchain.
          </li>
          <li>
            You will be prompted to sign up and create an account with MoonPay
            if you have not already done so. Follow the prompts to complete the
            standard KYC onboarding process. This can take a few minutes so we
            recommend funding your wallet in advance of the drop if you plan to
            use MoonPay to add funds.
          </li>
          <li>
            Now enter your wallet address. You can find this under ‘Account
            Details&apos; on your MetaMask profile. Copy to clipboard and paste
            it in the field.
          </li>
          <li>
            Once you confirm, MoonPay will automatically connect your MetaMask
            account address.
          </li>
          <li>
            Note: If you are using the MetaMask mobile app, you also have the
            option to pay using debit/credit card, Apple Pay, or instant bank
            transfer.
          </li>
        </ul>
      </>
    ),
  },
  {
    question:
      "How to purchase ETH as a NY resident (or another region not supported by MoonPay)",
    answer: (
      <>
        Some states in the US and certain countries do not yet support directly
        purchasing cryptocurrencies due to regulatory licensing. If this impacts
        you, you can purchase crypto on a centralized exchange such as Coinbase,
        and then send it to your MetaMask following the steps below: <br />
        <br />
        <ul>
          <li>Create a Coinbase wallet via coinbase.com</li>

          <li>Complete the KYC process as prompted to do so</li>
          <li>Connect your bank account and/or credit card</li>

          <li>Purchase Ethereum</li>

          <li>In MetaMask, copy your wallet address</li>

          <li>
            In Coinbase, navigate to the Send/Receive Payment portion of the
            application
          </li>

          <li>
            Select the currency you want to send from Coinbase to MetaMask
            (ETH), the amount you want to send, and paste your MetaMask wallet
            address as the recipient
          </li>

          <li>
            We suggest doing a test with a small amount of funds first to make
            sure you have the correct recipient address, since misplaced funds
            cannot be recovered. Sending funds is not always processed
            instantly, so we also recommend doing this process well in advance
            of the drop going live
          </li>
        </ul>
      </>
    ),
  },
  {
    question: "How do I complete my NFT purchase?",
    answer: (
      <>
        To purchase a piece from the collection, simply hit the ‘Mint Now&apos;
        button and complete the following steps:
        <br />
        <br />
        <ul className="m-0">
          <li>Connect your MetaMask wallet to the site</li>

          <li>
            Make sure you have enough ETH in your wallet to cover the cost of
            the NFT plus a minimal gas fee
          </li>

          <li>Sign a transaction to confirm your NFT purchase</li>
        </ul>
        <br />
        Make sure to set a reminder for when the drop goes live.
      </>
    ),
  },
  {
    question: "Where can I view my NFT?",
    answer: (
      <>
        Now that you&apos;ve created your MetaMask wallet and have your own
        address, you can use it to connect to other sites like the{" "}
        <a target="_blank" href="https://app.nft.org/" rel="noreferrer">
          NFT.org DEX
        </a>{" "}
        and OpenSea.io, where you can view your collection. If you do not
        immediately see your NFT, please check the Hidden tab in your account on
        OpenSea.
      </>
    ),
  },
  {
    question: "What blockchain are these NFTs minted on?",
    answer: "All of the NFTs from this collection are minted on Ethereum.",
  },
  {
    question: "Can I buy more than one NFT?",
    answer: "Of course! You can purchase as many as you would like.",
  },
  {
    question: "How do I prepare for the drop?",
    answer:
      "Set a reminder for the day the collection goes live. Make sure you've installed and set up a MetaMask wallet and purchased or sent enough ETH to cover the cost of the piece(s) you want to purchase, as well as any gas fees required to transact, and you should be good to go!",
  },
  {
    question: "Can I resell my NFT?",
    answer: (
      <>
        Of course! Your NFT will be available for resale on OpenSea.io or{" "}
        <a target="_blank" href="https://app.nft.org/" rel="noreferrer">
          NFT.org
        </a>{" "}
        shortly after your transaction is processed.
      </>
    ),
  },
  {
    question: "Read through the FAQ and still need help?",
    answer: (
      <>
        If you have read through the FAQ and still need assistance, please reach
        out to{" "}
        <a
          href="https://share.hsforms.com/1dAyUO24nTiC9E28ri0tnfA5jqzu"
          target="_blank"
          rel="noreferrer"
        >
          Blockparty Support
        </a>{" "}
        so we can help you out!
      </>
    ),
  },
];

// Polygon Template
// export const faqsContent = [
//   {
//     question: "How many NFTs are there and how much will they cost?",
//     answer: "All NFTs in this series will be available for 0.07 ETH.",
//   },
//   {
//     question: "Will the NFTs be sold on open marketplaces?",
//     answer: (
//       <>
//         The initial sale will take place solely on this site. Please confirm
//         you&apos;re on the correct site before connecting your wallet. If the
//         drop is sold out or you want a specific edition number you&apos;ll be
//         able to purchase on the secondary market of your choice that supports
//         trading of Polygon-based NFTs, such as the{" "}
//         <b>
//           <a
//             href="https://www.nft.org"
//             target="_blank"
//             rel="noreferrer"
//             style={{ whiteSpace: "nowrap" }}
//           >
//             NFT.org DEX.
//           </a>
//         </b>
//       </>
//     ),
//   },
//   {
//     question: "How do I purchase an NFT?",
//     answer: (
//       <>
//         <ul>
//           <li>
//             {" "}
//             Download and install the MetaMask Chrome Extension or mobile app*
//           </li>
//           <li> Add the Polygon (MATIC) Network to your MetaMask</li>
//           <li>
//             {" "}
//             Fund your wallet with enough MATIC to cover the cost of the NFT plus
//             fees
//           </li>
//           <li>
//             {" "}
//             Connect to our mint site with your MetaMask wallet (you will be
//             prompted to switch to the Polygon Network if not already on it){" "}
//           </li>
//           <li>
//             {" "}
//             Click Mint Now to complete your transaction and mint your NFT
//           </li>
//         </ul>
//         <br />
//         *NOTE: The optimal checkout experience is supported on desktop using
//         Google Chrome or Firefox with the MetaMask wallet browser extension
//         installed. If you would like to purchase on mobile, please download the
//         MetaMask wallet mobile app, connect your wallet, and navigate to this
//         site via the in-app MetaMask browser to complete your purchase.
//       </>
//     ),
//   },
//   {
//     question: "How to download and install MetaMask on desktop (recommended)",
//     answer: (
//       <>
//         To set up a MetaMask wallet follow the steps below:
//         <br />
//         <br />
//         <ul>
//           <li>
//             Visit MetaMask.io and select ‘Download&apos;. Select how you want to
//             install the program, on Chrome, iOS, or Android (Chrome browser
//             extension is recommended) extension is recommended)
//           </li>
//           <li>
//             Once you have installed MetaMask, set your password. Choose a strong
//             password and protect it
//           </li>
//           <li>
//             IMPORTANT: You will then see your 12-word seed phrase. This is a
//             secret recovery key that can be used to restore your account if you
//             get locked out. Write this seed phrase down on paper and keep it
//             safe! If you lose this you are at risk of permanently losing access
//             to your wallet and the contents stored within it. MetaMask is a
//             third-party application and Blockparty is not responsible for nor
//             capable of recovering any NFTs or funds custodied in a MetaMask
//             wallet.
//           </li>
//           <li>
//             Once you have recorded your seed phrase you will be ready to use
//             MetaMask!
//           </li>
//         </ul>
//       </>
//     ),
//   },
//   {
//     question: "How to download and install MetaMask on mobile",
//     answer: (
//       <>
//         To set up a MetaMask wallet follow the steps below:*. <br />
//         <br />
//         <ul>
//           <li>
//             Visit the Google Playstore on your Android device or the App Store
//             on your iOS device.
//           </li>
//           <li>Search for MetaMask and download the mobile app.</li>
//           <li>
//             Once you have installed MetaMask, set your password. Choose a strong
//             password and protect it.
//           </li>
//           <li>
//             IMPORTANT: You will then see your 12-word seed phrase. This is a
//             secret recovery key that can be used to restore your account if you
//             get locked out. Write this seed phrase down on paper and keep it
//             safe! If you lose this you are at risk of permanently losing access
//             to your wallet and the contents stored within it. MetaMask is a
//             third-party application and Blockparty is not responsible for nor
//             capable of recovering any NFTs or funds custodied in a MetaMask
//             wallet.
//           </li>
//           <li>
//             Once you have recorded your seed phrase you will be ready to use
//             MetaMask!
//           </li>
//         </ul>
//         <br />
//         *NOTE: If you want to use an existing MetaMask wallet on your mobile
//         app, select ‘Import Wallet’ and use the 12-word seed phrase from your
//         original wallet to add it in the mobile app.
//       </>
//     ),
//   },
//   {
//     question:
//       "How to add the Polygon Network to your MetaMask (required to fund your wallet with MATIC)",
//     answer: (
//       <>
//         Before you can add funds to your MetaMask wallet, you will need to
//         change the network MetaMask is connected to from Ethereum Mainnet to
//         Polygon (MATIC) Mainnet. <br />
//         <br />
//         <ul>
//           <li>
//             Navigate to{" "}
//             <strong>
//               <a
//                 href="https://polygonscan.com/"
//                 target="_blank"
//                 rel="noreferrer"
//               >
//                 Polygonscan.com
//               </a>
//             </strong>
//           </li>
//           <li>
//             Scroll down to the bottom of the page and click on the “Add Polygon
//             Network” button on the bottom righthand side.
//           </li>
//           <li>
//             Once you click the button you will see a Metamask Notification, now
//             click on Approve. You will be directly switched to Polygon (MATIC)
//             Mainnet now in the network dropdown list. You can now close the
//             dialog.
//           </li>
//         </ul>
//         <br />
//         NOTE: when on our mint site (on desktop and mobile), you will
//         automatically prompted to Add the Polygon Network and/or Switch to the
//         Polygon Network if you have not already done so.
//       </>
//     ),
//   },
//   {
//     question: "How to fund your MetaMask with MATIC",
//     answer: (
//       <>
//         Next, you&apos;ll need to add funds so you can make your purchase. If
//         you already have crypto in your wallet, you can convert it to MATIC
//         using the Swap functionality in your MetaMask wallet.
//         <br />
//         <br />
//         If you do not have crypto in your wallet, MoonPay enables users in most
//         regions to buy MATIC directly in your MetaMask wallet with a credit or
//         debit card following the steps below. NY residents skip to the following
//         section for more info on how to purchase MATIC.
//         <ul>
//           <li>
//             Open your MetaMask wallet and check that you are connected to MATIC
//             Mainnet Network
//           </li>
//           <li>Select the ‘Buy&apos; button</li>
//           <li>Scroll down to Moonpay and select ‘Continue to MoonPay&apos;</li>
//           <li>
//             Choose the ‘Currency&apos; and ‘Amount&apos;. The amount of MATIC
//             you buy will depend on the price of the NFT. Make sure to add a
//             little bit more to cover the gas fees associated with processing
//             transactions on the blockchain.
//           </li>
//           <li>
//             You will be prompted to sign up and create an account with MoonPay
//             if you have not already done so. Follow the prompts to complete the
//             standard KYC onboarding process. This can take a few minutes so we
//             recommend funding your wallet in advance of the drop if you plan to
//             use MoonPay to add funds.
//           </li>
//           <li>
//             Now enter your wallet address. You can find this under ‘Account
//             Details&apos; on your MetaMask profile. Copy to clipboard and paste
//             it in the field.
//           </li>

//           <li>
//             Once you confirm, MoonPay will automatically connect your MetaMask
//             account address.
//           </li>
//           <li>
//             Note: If you are using the MetaMask mobile app, you also have the
//             option to pay using debit/credit card, Apple Pay, or instant bank
//             transfer.
//           </li>
//         </ul>
//       </>
//     ),
//   },
//   {
//     question:
//       "How to purchase MATIC as a NY resident (or another region not supported by MoonPay)",
//     answer: (
//       <>
//         Some states in the US and certain countries do not yet support directly
//         purchasing cryptocurrencies due to regulatory licensing. If this impacts
//         you, you can purchase crypto on a centralized exchange such as Coinbase,
//         and then send it to your MetaMask following the steps below: <br />
//         <br />
//         <ul>
//           <li>Create a Coinbase wallet via coinbase.com</li>

//           <li>Complete the KYC process as prompted to do so</li>
//           <li>Connect your bank account and/or credit card</li>

//           <li>Purchase MATIC (Polygon)</li>

//           <li>In MetaMask, copy your wallet address</li>

//           <li>
//             In Coinbase, navigate to the Send/Receive Payment portion of the
//             application
//           </li>

//           <li>
//             Select the currency you want to send from Coinbase to MetaMask
//             (MATIC), the amount you want to send, and paste your MetaMask wallet
//             address as the recipient
//           </li>

//           <li>
//             We suggest doing a test with a small amount of funds first to make
//             sure you have the correct recipient address, since misplaced funds
//             cannot be recovered. Sending funds is not always processed
//             instantly, so we also recommend doing this process well in advance
//             of the drop going live
//           </li>
//         </ul>
//       </>
//     ),
//   },
//   {
//     question: "How do I complete my NFT purchase?",
//     answer: (
//       <>
//         To purchase a piece from the collection, simply hit the ‘Mint Now&apos;
//         button and complete the following steps:
//         <br />
//         <br />
//         <ul className="m-0">
//           <li>
//             Connect your MetaMask wallet to the site and switch to the Polygon
//             Network when prompted to do so
//           </li>

//           <li>
//             Make sure you have enough MATIC in your wallet to cover the cost of
//             the NFT plus a minimal gas fee
//           </li>

//           <li>Sign a transaction to confirm your NFT purchase</li>
//         </ul>
//         <br />
//         Make sure to set a reminder for when the drop goes live.
//       </>
//     ),
//   },
//   {
//     question: "Where can I view my NFT?",
//     answer: (
//       <>
//         Now that you&apos;ve created your MetaMask wallet and have your own
//         address, you can use it to connect to other sites like{" "}
//         <a target="_blank" href="https://app.nft.org/" rel="noreferrer">
//           NFT.org
//         </a>{" "}
//         and OpenSea.io, where you can view your collection. If you do not
//         immediately see your NFT, please check the Hidden tab in your account on
//         OpenSea.
//       </>
//     ),
//   },
//   {
//     question: "What blockchain are these NFTs minted on?",
//     answer: "All of the NFTs from this collection are minted on Polygon.",
//   },
//   {
//     question: "Can I buy more than one NFT?",
//     answer: "Of course! You can purchase as many as you would like.",
//   },
//   {
//     question: "How do I prepare for the drop?",
//     answer:
//       "Set a reminder for the day the collection goes live. Make sure you&apos;ve installed and set up a MetaMask wallet and purchased or sent enough MATIC to cover the cost of the piece(s) you want to purchase, as well as any gas fees required to transact, and you should be good to go!",
//   },
//   {
//     question: "Can I resell my NFT?",
//     answer:
//       "Of course! Your NFT will be available for resale on OpenSea.io or NFT.org shortly after your transaction is processed.",
//   },
//   {
//     question: "Read through the FAQ and still need help?",
//     answer: (
//       <>
//         If you have read through the FAQ and still need assistance, please reach
//         out to{" "}
//         <a
//           href="https://share.hsforms.com/1dAyUO24nTiC9E28ri0tnfA5jqzu"
//           target="_blank"
//           rel="noreferrer"
//         >
//           Blockparty Support
//         </a>{" "}
//         so we can help you out!
//       </>
//     ),
//   },
// ];
