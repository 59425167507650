import { Base } from "@src/core/models/base";

export interface Order extends Base {
  assetId: string;
  offerId: string;
  status: OrderStatus;
  price: number;
  transaction?: OrderTransaction;
  wallet: string;
  voucher: Voucher;
}

export interface Voucher extends Base {
  key: string;
  from: string;
  to: string;
  price: string;
  uri: string;
  signature: string;
}

export interface OrderTransaction {
  transactionHash: string;
}

export enum OrderStatus {
  AwatingConfirmation = "awaiting_confirmation",
  Failed = "failed",
  Finished = "finished",
}
