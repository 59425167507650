import axios, { AxiosResponse } from "axios";
import { Base } from "@src/core/models/base";
import { PayloadMapper } from "@src/core/api/payload-mapper";

async function get<TModel extends Base>(
  url: string,
  callback: (response: TModel) => void
): Promise<void> {
  axios
    .get(url)
    .then((response: AxiosResponse) => {
      callback(PayloadMapper.map(response.data.body.data));
    })
    .catch((error) => console.log(error));
}

async function list<TModel extends Base>(
  url: string,
  callback: (response: TModel[]) => void
): Promise<void> {
  axios
    .get(url)
    .then((response: AxiosResponse) => {
      callback(PayloadMapper.mapMany(response.data.body.data));
    })
    .catch((error) => console.log(error));
}

async function post<TModel extends Base>(
  url: string,
  requestBody: any,
  callback: (response: TModel) => void
): Promise<void> {
  axios
    .post(url, requestBody, {
      headers: {
        "Content-Type": "application/vnd.api+json",
      },
    })
    .then((response: AxiosResponse) => {
      callback(PayloadMapper.map(response.data.body.data));
    })
    .catch((error) => console.log(error));
}

async function put<TModel extends Base>(
  url: string,
  requestBody: any,
  callback: (response: TModel) => void
): Promise<void> {
  axios
    .put(url, requestBody, {
      headers: {
        "Content-Type": "application/vnd.api+json",
      },
    })
    .then((response: AxiosResponse) => {
      callback(PayloadMapper.map(response.data.body.data));
    })
    .catch((error) => console.log(error));
}

async function delete_item<TModel extends Base>(
  url: string,
  callback: (response: TModel) => void
): Promise<void> {
  axios
    .delete(url, {
      headers: {
        "Content-Type": "application/vnd.api+json",
      },
    })
    .then((response: AxiosResponse) => {
      console.log(response);
      callback(response.data);
    })
    .catch((error) => console.log(error));
}

export const HttpClient = {
  get,
  list,
  post,
  put,
  delete_item,
};
