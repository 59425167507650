import { Drop } from "@src/core/models/drop";
import DateCountdown from "@src/components/shared/date-countdown";
import * as Styles from "./styles";

interface Props {
  drop: Drop;
  onComplete: () => void;
}

const DropStartCountdown = ({ drop, onComplete }: Props): JSX.Element => {
  return (
    <Styles.Container>
      <DateCountdown
        captionText="Arriving in"
        onComplete={onComplete}
        targetDate={drop.startsAt}
      />
    </Styles.Container>
  );
};

export default DropStartCountdown;
