import faker from "@faker-js/faker";
import { Creator } from "@src/core/models/creator";

export const mockCreator = (overrides?: Partial<Creator>): Creator => ({
  bio: faker.lorem.paragraph(),
  createdAt: faker.date.past(),
  createdBy: faker.datatype.uuid(),
  hashId: faker.datatype.uuid(),
  id: faker.datatype.uuid(),
  name: faker.name.findName(),
  profileImageUrl: faker.image.lorempicsum.avatar(),
  role: faker.name.jobTitle(),
  socialMedia: [
    { type: "facebook", url: faker.internet.url() },
    { type: "instagram", url: faker.internet.url() },
    { type: "pinterest", url: faker.internet.url() },
    { type: "twitter", url: faker.internet.url() },
    { type: "website", url: faker.internet.url() },
  ],
  updatedAt: faker.date.past(),
  updatedBy: faker.datatype.uuid(),
  ...(overrides || {}),
});
