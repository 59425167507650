import blp from "@src/assets/images/blp.png";
import SocialLinks from "@/src//components/SocialLinks";

const Footer = (): JSX.Element => {
  return (
    <>
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-12">
              <div className="privacy">
                <p className="m-0">
                  <a
                    href="https://blockparty.co/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy
                  </a>
                  <span>•</span>
                  <a
                    href="https://blockparty.co/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="power">
                <a
                  href="https://blockparty.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>POWERED BY</span>
                  <img src={blp} alt="Blockparty Powered By" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <SocialLinks
                align="right"
                color="white"
                twitter="https://twitter.com/blockparty"
                marketplace="https://blockparty.co/marketplace"
                instagram="https://www.instagram.com/goblockparty"
                discord="https://discord.com/invite/qp9DqgrMCu"
              />
            </div>
          </div>
        </div>
      </footer>

      {/* <footer className="footer-area mobile">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-8">
              <div className="power">
                <a
                  href="https://blockparty.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>POWERED BY</span>
                  <img src={blp} alt="Blockparty Powered by" />
                </a>
              </div>
            </div>
            <div className="col-12">
              <SocialLinks
                align="center"
                color="white"
                twitter="https://twitter.com/blockparty"
                marketplace="https://blockparty.co/marketplace"
                instagram="https://www.instagram.com/goblockparty"
                discord="https://discord.com/invite/qp9DqgrMCu"
              />
            </div>
            <div className="col-xl-4 col-md-2">
              <div className="privacy">
                <p className="m-0">
                  <a
                    href="https://blockparty.co/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy
                  </a>
                  <span>•</span>
                  <a
                    href="https://blockparty.co/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </>
  );
};

export default Footer;
