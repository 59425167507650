import { Order } from "@src/core/models/order";
import { Wallet, WalletType } from "@src/core/models/wallet";
import color from "@src/design-tokens/color";
import * as Styles from "./styles";

interface Props {
  order: Order;
  wallet: Wallet;
}

const CheckoutMoonpay = ({ wallet, order }: Props): JSX.Element => {
  return (
    <Styles.Wrapper>
      <iframe
        allow="accelerometer; autoplay; camera; gyroscope; payment"
        frameBorder="0"
        height="100%"
        src={`${process.env.REACT_APP_MOONPAY_CHECKOUT_URL}?${formatQueryString(
          order,
          wallet
        )}`}
        title="moonpay"
        width="100%"
      />
    </Styles.Wrapper>
  );
};

const makeWidgetOptions = (
  order: Order,
  wallet: Wallet
): { [key: string]: string | undefined } => {
  const orderPrice = order.price - wallet.balance;
  const minRequiredPrice = 0.011;

  return {
    apiKey: process.env.REACT_APP_MOONPAY_API_KEY || "",
    baseCurrencyCode: "usd",
    colorCode: color.primary.light,
    currencyCode: parseWalletCurrency(wallet),
    lockAmount: "true",
    externalCustomerId: wallet.id,
    externalTransactionId: order.id,
    quoteCurrencyAmount: Math.max(orderPrice, minRequiredPrice).toString(),
    redirectURL: `${process.env.REACT_APP_DOMAIN}/checkout-new/${order.id}`,
    showWalletAddressForm: "true",
    walletAddress: wallet.id,
  };
};

const parseWalletCurrency = (wallet: Wallet): string => {
  const currencies: { [key: string]: string } = {};

  currencies[WalletType.Blocto] = "flow";
  currencies[WalletType.MetaMask] = "eth";

  return currencies[wallet.type];
};

const formatQueryString = (order: Order, wallet: Wallet): string => {
  const queryString = new URLSearchParams();
  const widgetOptions = makeWidgetOptions(order, wallet);

  Object.keys(widgetOptions).forEach((key: string) => {
    queryString.append(key, widgetOptions[key] as string);
  });

  return queryString.toString();
};

export default CheckoutMoonpay;
