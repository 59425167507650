import { ReactElement } from "react";
import * as Styles from "./styles";

interface Props {
  children: ReactElement;
}

const GlobalStyleWrapper = ({ children }: Props): JSX.Element => {
  return (
    <>
      <Styles.GlobalStyleWrapper />
      <Styles.BootstrapOverrides />
      {children}
    </>
  );
};

export default GlobalStyleWrapper;
