import { useState } from "react";
import { Offer } from "@src/core/models/offer";
import OfferThumbnail from "@src/components/offers/thumbnail";
import OfferContentModal from "./content-modal";
import * as Styles from "./styles";

interface Props {
  offer: Offer;
}

const OfferContentPreview = ({ offer }: Props): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Styles.OfferContentPreview>
      <OfferThumbnail offer={offer} previewSize="large" muted />
      <OfferContentModal
        isVisible={showModal}
        offer={offer}
        onClose={() => setShowModal(false)}
      />
    </Styles.OfferContentPreview>
  );
};

export default OfferContentPreview;
