import { useEffect, useState } from "react";
import { Drop } from "@src/core/models/drop";
import DropEndCountdown from "./end-countdown";
import DropStartCountdown from "./start-countdown";
import * as Styles from "./styles";

interface DropCountdownParams {
  drop: Drop;
  dropIsActive?: boolean;
  dropIsExpired?: boolean;
}

const DropCountdown = ({
  drop,
  dropIsActive,
  dropIsExpired,
}: DropCountdownParams): JSX.Element => {
  const [showStartCountdown, setShowStartCountdown] = useState<boolean>(false);
  const [showEndCountdown, setShowEndCountdown] = useState<boolean>(false);

  useEffect(() => {
    setShowStartCountdown(!dropIsActive && !dropIsExpired);
    setShowEndCountdown(!!dropIsActive && !dropIsExpired);
  }, [dropIsActive, dropIsExpired]);

  return (
    <Styles.Wrapper>
      {showStartCountdown && (
        <DropStartCountdown
          drop={drop}
          onComplete={() => {
            setShowStartCountdown(false);
            setShowEndCountdown(true);
          }}
        />
      )}
      {!!drop.endsAt && showEndCountdown && (
        <DropEndCountdown
          targetDate={drop.endsAt}
          onComplete={() => setShowEndCountdown(false)}
        />
      )}
    </Styles.Wrapper>
  );
};

DropCountdown.defaultProps = {
  dropIsActive: undefined,
  dropIsExpired: undefined,
};

export default DropCountdown;
