export const formatPrice = (value: number): string => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const formatEth = (value: number): string => {
  return `${formatPrice(value)}ETH`;
};

export const formatMatic = (value: number): string => {
  return `${formatPrice(value)} MATIC`;
};
export const formatUsd = (value: number): string => {
  return `$ ${formatPrice(value)}`;
};
