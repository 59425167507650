import * as Styles from "./styles";

const DropExpiredMessage = (): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Styles.CaptionText>This drop has expired</Styles.CaptionText>
    </Styles.Wrapper>
  );
};

export default DropExpiredMessage;
