import {
  FaFacebookF,
  FaTwitter,
  FaDiscord,
  FaInstagram,
  FaGlobe,
  FaStore,
} from "react-icons/fa";
import { Links } from "./styles";

export type SocialLinkProps = {
  align?: "left" | "right" | "center";
  color?: "black" | "white";
  twitter?: string;
  instagram?: string;
  facebook?: string;
  website?: string;
  marketplace?: string;
  discord?: string;
};

const SocialLinks = ({
  twitter,
  instagram,
  facebook,
  website,
  marketplace,
  discord,
  color = "black",
  align = "left",
}: SocialLinkProps): JSX.Element => {
  return (
    <Links style={{ color, content: align }}>
      {twitter && (
        <a href={twitter} target="_blank" rel="noreferrer">
          <span>Twitter</span>
          <FaTwitter />
        </a>
      )}
      {instagram && (
        <a href={instagram} target="_blank" rel="noreferrer">
          <span>Instagram</span>
          <FaInstagram />
        </a>
      )}
      {facebook && (
        <a href={facebook} target="_blank" rel="noreferrer">
          <span>Facebook</span>
          <FaFacebookF />
        </a>
      )}
      {website && (
        <a href={website} target="_blank" rel="noreferrer">
          <span>Website</span>
          <FaGlobe />
        </a>
      )}
      {marketplace && (
        <a href={marketplace} target="_blank" rel="noreferrer">
          <span>Marketplace</span>
          <FaStore />
        </a>
      )}
      {discord && (
        <a href={discord} target="_blank" rel="noreferrer">
          <span>Discord</span>
          <FaDiscord />
        </a>
      )}
    </Links>
  );
};

export default SocialLinks;
