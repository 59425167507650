import { ReactElement } from "react";
import { FaTwitter } from "react-icons/fa";
import { ArtistSectionWrapper } from "./styles";

type Props = {
  name: string;
  twitter: string;
  description: string | ReactElement;
  id: number;
};

export const ArtistSection = ({
  name,
  twitter,
  description,
  id,
}: Props): JSX.Element => {
  return (
    <ArtistSectionWrapper
      className="about-area artist-section"
      id={`artist_${id}`}
    >
      <div className="container about-container">
        <div className="artist-title">
          <div className="name-row">
            <h2>{name}</h2>
            {twitter && (
              <a className="link twitter-link" href={twitter}>
                <FaTwitter />
                <span>{name}&apos;s twitter</span>
              </a>
            )}
          </div>
          <p>{description}</p>
        </div>
      </div>
    </ArtistSectionWrapper>
  );
};
