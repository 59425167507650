import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 6rem;
`;

export const SectionTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  margin-bottom: 4rem;
  text-align: center;

  & > * {
    max-width: 40rem;
  }
`;
