import { Helmet } from "react-helmet";
import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Creator } from "@src/core/models/creator";
import { Drop } from "@src/core/models/drop";
import { Offer } from "@src/core/models/offer";
import { formatEth } from "@src/core/support/format-currency";
import DropCountdown from "@src/components/drops/countdown";
import OfferContentPreview from "@src/components/offers/content-preview";
import OfferCallToAction from "./call-to-action";
import { timezoneOffset } from "@/src/core/support/timezone-offset";
import { dropContent } from "@/src/data/static/dropContent";
import * as Styles from "./styles";

interface Props {
  creator: Creator;
  drop?: Drop;
  dropIsActive?: boolean;
  dropIsExpired?: boolean;
  offer: Offer;
}

const OfferDetails = ({
  creator,
  drop,
  dropIsActive,
  dropIsExpired,
  offer,
}: Props): JSX.Element => {
  const countdown = useMemo(() => {
    if (!drop) {
      return <></>;
    }

    const tempStartDate = timezoneOffset(new Date(drop.startsAt));
    const hideCountdown = tempStartDate.getTime() <= new Date().getTime();

    if (hideCountdown) {
      return <></>;
    }

    return (
      <DropCountdown
        drop={drop}
        dropIsActive={dropIsActive}
        dropIsExpired={dropIsExpired}
      />
    );
  }, [drop]);

  return (
    <Styles.Wrapper>
      <Helmet>
        <title>{`"${offer.name}" by ${offer.artist} from ${dropContent.title}`}</title>
        <meta
          property="og:title"
          content={`"${offer.name}" by ${offer.artist} from ${dropContent.title}`}
        />
        <meta name="description" content={offer.description} />
        <meta property="og:description" content={offer.description} />
      </Helmet>
      <Container>
        <Row>
          <Col sm={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
            {countdown}
            <OfferContentPreview offer={offer} />
          </Col>
          <Col sm={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
            <Styles.ContentWrapper>
              <Styles.Title>{offer.name}</Styles.Title>
              <p>{offer.description}</p>
              {renderTags(offer, creator)}
              {renderCallToAction(offer)}
            </Styles.ContentWrapper>
          </Col>
        </Row>
      </Container>
    </Styles.Wrapper>
  );
};

OfferDetails.defaultProps = {
  drop: undefined,
  dropIsActive: undefined,
  dropIsExpired: undefined,
};

const renderCallToAction = (offer: Offer): JSX.Element => {
  return (
    <Styles.CallToActionWrapper>
      <OfferCallToAction offer={offer} />
    </Styles.CallToActionWrapper>
  );
};

const renderTags = (offer: Offer, creator: Creator): JSX.Element => {
  return (
    <Styles.TagsWrapper>
      <Styles.CreatorTag>
        {/* <Styles.CreatorImage roundedCircle src={creator.profileImageUrl} /> */}
        <div>
          <Styles.TagLabel>Artist</Styles.TagLabel>
          <Styles.TagText>{offer.artist}</Styles.TagText>
        </div>
      </Styles.CreatorTag>
      <Styles.PriceTag>
        <Styles.TagLabel>Price</Styles.TagLabel>
        <Styles.TagText>{formatEth(offer.price)}</Styles.TagText>
      </Styles.PriceTag>
    </Styles.TagsWrapper>
  );
};

export default OfferDetails;
