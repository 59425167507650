import { Card } from "react-bootstrap";
import { Creator } from "@src/core/models/creator";
import * as Styles from "./styles";

interface Props {
  creator: Creator;
}

const CreatorCard = ({ creator }: Props): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Card>
        <Card.Body>
          <Styles.ImageWrapper>
            <Styles.CreatorImage roundedCircle src={creator.profileImageUrl} />
          </Styles.ImageWrapper>
          <Styles.Name>{creator.name}</Styles.Name>
          <Styles.Role>{creator.role}</Styles.Role>
        </Card.Body>
      </Card>
    </Styles.Wrapper>
  );
};

export default CreatorCard;
