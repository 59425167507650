import padding from "@src/design-tokens/padding";
import styled from "styled-components";

export const WalletDetails = styled.div`
  * {
    color: var(--main-text-color);
    font-weight: bold;
  }
  > div {
    background: var(--main-bg-color);
    padding: ${padding.p2} ${padding.p4};
    border-radius: 8px;
    border: solid 1px var(--accent-50);
    box-shadow: 0px 0px 16px var(--accent-10);
    * {
      pointer-events: none;
    }
    > div {
      margin-right: 0;
    }
    img {
      width: 18px;
    }
    @media (min-width: 992px) {
      margin-left: ${padding.p2};
    }
  }
`;
