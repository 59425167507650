import styled from "styled-components";
import border from "@src/design-tokens/border";
import color from "@src/design-tokens/color";
import display from "@src/design-tokens/display";
import fontWeight from "@src/design-tokens/font-weight";
import margin from "@src/design-tokens/margin";
import padding from "@src/design-tokens/padding";

export const CaptionText = styled.div`
  color: var(--main-text-color);
  font-size: 1rem;
  font-weight: ${fontWeight.medium};
  margin-right: ${margin.m4};

  @media (max-width: ${display.md}) {
    margin-right: ${margin.m0};
    margin-bottom: ${margin.m2};
  }
`;

export const CountdownBox = styled.div`
  display: inline-box;
  margin: ${margin.m0} ${margin.m2};
`;

export const Countdown = styled.div`
  font-size: 1.75rem;
  line-height: 1;
  font-weight: ${fontWeight.bold};
  min-width: 22rem;
  text-align: center;
  white-space: nowrap;

  @media (max-width: ${display.md}) {
    font-size: 1.5rem;
    min-width: 0;
  }
`;

export const CountdownContainer = styled.div`
  align-items: center;
  background-color: var(--accent-10);
  border-radius: ${border.radius.none};
  color: ${color.neutral.medium};
  display: flex;
  justify-content: center;
  padding: ${padding.p4} ${padding.p6};

  @media (max-width: ${display.md}) {
    flex-flow: column;
  }
`;

export const Wrapper = styled.div``;
