const color = {
  neutral: {
    medium: "#FFFFFF",
    light: "#EEEEEE",
  },
  primary: {
    medium: "#19171B",
    light: "#454545",
  },
};

export default color;
