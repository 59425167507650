import { Offer } from "@src/core/models/offer";
import TitleSubSection from "@src/components/shared/title/sub-section";
import * as Styles from "./styles";

interface Props {
  offer: Offer;
}

const OfferOverview = ({ offer }: Props): JSX.Element => {
  return (
    <Styles.Wrapper>
      <TitleSubSection text="Item overview" />
      <Styles.List>
        <Styles.ListItem>
          <div>Size</div>
          <div>3000 x 3000 cm</div>
        </Styles.ListItem>
        <Styles.ListItem>
          <div>Created at</div>
          <div>31 March 2022</div>
        </Styles.ListItem>
        <Styles.ListItem>
          <div>Category</div>
          <div>Art work</div>
        </Styles.ListItem>
        <Styles.ListItem>
          <div>Chain ID</div>
          <div>#12159EDT23</div>
        </Styles.ListItem>
      </Styles.List>
    </Styles.Wrapper>
  );
};

export default OfferOverview;
