import { useContext } from "react";
import { BloctoWalletContext } from "@src/core/contexts/blocto-wallet";
import { MetaMaskWalletContext } from "@src/core/contexts/meta-mask-wallet";
import WalletDetailsBlocto from "./blocto";
import WalletDetailsMetaMask from "./meta-mask";
import * as Styles from "./styles";

const WalletDetails = (): JSX.Element => {
  const { bloctoWallet } = useContext(BloctoWalletContext);
  const { metaMaskWallet } = useContext(MetaMaskWalletContext);

  return (
    <Styles.WalletDetails>
      {bloctoWallet && <WalletDetailsBlocto wallet={bloctoWallet} />}
      {metaMaskWallet && <WalletDetailsMetaMask wallet={metaMaskWallet} />}
    </Styles.WalletDetails>
  );
};

export default WalletDetails;
