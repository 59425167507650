import ReactCountdown, { zeroPad } from "react-countdown";
import { timezoneOffset } from "@/src/core/support/timezone-offset";
import * as Styles from "./styles";

interface Props {
  captionText: string;
  onComplete: () => void;
  targetDate: Date;
}

interface TimerProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const DateCountdown = ({
  captionText,
  onComplete,
  targetDate,
}: Props): JSX.Element => {
  const target = timezoneOffset(new Date(targetDate));

  return (
    <Styles.Wrapper>
      <Styles.CountdownContainer>
        <Styles.CaptionText>{captionText}</Styles.CaptionText>
        <ReactCountdown
          date={target}
          now={() => new Date().getTime()}
          onComplete={onComplete}
          renderer={renderCountdown}
        />
      </Styles.CountdownContainer>
    </Styles.Wrapper>
  );
};

const renderCountdown = (props: TimerProps) => {
  return (
    <Styles.Countdown>
      {formatCountdownNumber(props.days, "D")}
      {formatCountdownNumber(props.hours, "H")}
      {formatCountdownNumber(props.minutes, "M")}
      {formatCountdownNumber(props.seconds, "S", false)}
    </Styles.Countdown>
  );
};

const formatCountdownNumber = (
  value: number,
  caption: string,
  showSeparator = true
) => {
  return `${zeroPad(value)}${caption}${showSeparator ? " : " : ""}`;
};

export default DateCountdown;
