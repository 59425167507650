import { useContext, useState } from "react";
import { Nav } from "react-bootstrap";
import { MetaMaskWalletContext } from "@src/core/contexts/meta-mask-wallet";
import WalletDetails from "@src/components/wallet/details";
import WalletOpenModalButton from "@src/components/wallet/open-modal-button";
import prjkt2700Logo from "@src/assets/images/logo.png";
import { MessageBar } from "./styles";

const Header = (): JSX.Element => {
  const { metaMaskWallet } = useContext(MetaMaskWalletContext);
  const [messageBar, setMessageBar] = useState<boolean>(true);

  return (
    <>
      <MessageBar className={messageBar ? "show" : "hide"}>
        <div>
          Note: for the best experience use Chrome or Firefox with the MetaMask
          browser extension, or the MetaMask app&lsquo;s in-app browser. New to
          purchasing NFTs with MetaMask? Read our <a href="/faqs">FAQs</a>
        </div>
        <button type="button" onClick={() => setMessageBar(false)}>
          ✕
        </button>
      </MessageBar>
      <header className="header-area" id="home">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <div className="inner-header-container">
              <a className="navbar-brand studio" href="/">
                <div className="logo">
                  <img src={prjkt2700Logo} alt="PRJKT2700 Logo" />
                </div>
              </a>
              <div
                className="navbar-collapse colps"
                id="navbarNav"
                style={{ flexBasis: "content" }}
              >
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="/#about">
                      ABOUT
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/faqs">
                      FAQs
                    </a>
                  </li>
                  <li>
                    <Nav>
                      {!metaMaskWallet && <WalletOpenModalButton />}
                      {metaMaskWallet && <WalletDetails />}
                    </Nav>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
