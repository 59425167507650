import styled from "styled-components";
import margin from "@src/design-tokens/margin";

export const ErrorMessageWrapper = styled.div`
  text-align: center;
  color: var(--error-color);
`;

export const Icon = styled.div`
  font-size: 1.2rem;
  margin-right: ${margin.m3};
`;

export const Logo = styled.img`
  height: 1.2rem;
  margin-right: ${margin.m3};
`;

export const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1040;
`;

export const HighlightWrapper = styled.div`
  border: solid 1px var(--accent-50);
  border-radius: 8px;
  padding: 10px 20px 0px 20px;
  &.under-offers {
    padding: 0;
    border: none;
    margin-top: 48px;
    font-family: var(--body-font);
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 767px) {
      margin-top: 24px;
    }
    p {
      color: var(--error-color);
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.05em;
      margin: 0;
    }
  }
`;

export const OverlayLoadingMessage = styled.div``;

export const OverlayLoadingWrapper = styled.div`
  align-items: center;
  background-color: var(--main-bg-color);
  border: solid 1px var(--accent-50);
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 4rem;
  color: var(--main-text-color);
  * {
    color: var(--main-text-color);
  }

  .spinner-grow {
    margin-bottom: 2rem;
  }
`;
