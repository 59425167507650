import { ReactElement } from "react";
import { BloctoWalletProvider } from "@src/core/contexts/blocto-wallet";
import { CreatorProvider } from "@src/core/contexts/creator";
import { DropProvider } from "@src/core/contexts/drop";
import { EthereumRateProvider } from "@src/core/contexts/ethereum-rate";
import { MetaMaskWalletProvider } from "@src/core/contexts/meta-mask-wallet";

interface Props {
  children: ReactElement;
}

export const GlobalProviderWrapper = ({ children }: Props): JSX.Element => {
  return (
    <>
      <EthereumRateProvider>
        <MetaMaskWalletProvider>
          <BloctoWalletProvider>
            <CreatorProvider>
              <DropProvider>{children}</DropProvider>
            </CreatorProvider>
          </BloctoWalletProvider>
        </MetaMaskWalletProvider>
      </EthereumRateProvider>
    </>
  );
};
