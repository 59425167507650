import { ReactElement } from "react";
import Header from "@src/components/shared/header";
import Footer from "@src/components/shared/footer";
import GlobalStyleWrapper from "@src/components/shared/global-style-wrapper";
import * as Styles from "./styles";

interface Props {
  children: ReactElement;
}

const PageLayout = ({ children }: Props): JSX.Element => {
  return (
    <GlobalStyleWrapper>
      <Styles.Wrapper>
        <Header />
        {children}
        <Styles.BackgroundImage />
        <Footer />
      </Styles.Wrapper>
    </GlobalStyleWrapper>
  );
};

export default PageLayout;
